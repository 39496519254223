function Avatar(props) {
    return (
        <div id="avatar"
             data-tut="avatar"
             onClick={props.handleClick}
             className={`avatar ${props.className ? props.className : ''}`}
             style={{
                 backgroundColor: props.backgroundColor,
                 borderColor: props.borderColor ? props.borderColor : props.backgroundColor
             }}
        >
            <span className="avatar__text" style={{color: props.textColor}}>{props.children}</span>
        </div>
    )
}

export default Avatar;