import axiosAuth from "../../axios";
import SearchFilter from "../../components/SearchFilter";
import FilterBar from "../../components/FilterBar";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Link, useParams, useLocation, useOutletContext} from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";

//React Redux Imports
import {connect} from "react-redux";
import {downloadFile, deleteFile} from "../../store/actions";
import {getFileType, toTitleCase} from "../../utils/Helpers";
import DotDotDot from "../../components/DotDotDot";

function FinancialFiles(props) {
    const location = useLocation();
    const {projectId} = useParams();
    const {user, project} = useOutletContext();
    const [files, setFiles] = useState([]);
    const [factor, setFactor] = useState(localStorage.getItem(`${user._id}_${projectId}_financial_file_factor`) ?? 'created_at');
    const factors = [
        {label: 'Created date', value: 'created_at'},
        {label: 'Due date', value: 'dueDate'},
        {label: 'File name', value: 'filename'},
        {label: 'Amount', value: 'amount'},
        {label: 'No.', value: 'num'},
    ];
    const [filter, setFilter] = useState({
        category: 'invoices',
        descendingOrder: (localStorage.getItem(`${user._id}_${projectId}_financial_file_order`) ?? 'desc') === 'desc',
        query: '',
    });
    const [filteredFiles, setFilteredFiles] = useState([]);
    const sortFiles = useRef([...files]);

    const updateFilesSorting = useCallback(() => {
        localStorage.setItem(`${user._id}_${projectId}_financial_file_order`, filter.descendingOrder ? 'desc' : 'asc');
        localStorage.setItem(`${user._id}_${projectId}_financial_file_factor`, factor);

        if (sortFiles && sortFiles.current.length) {
            sortFiles.current.forEach((file) => {
                if (file.category === filter.category && document.querySelector(`.financial-files__row[data-fileid="${file._id}"]`)) {
                    let strDate = new Date(file.created_at);
                    let dueDate = new Date(file.dueDate);
                    let strConcat = '';
                    strConcat += file.filename + ' ';
                    strConcat += file.category + ' ';
                    strConcat += file.num + ' ';
                    strConcat += file.amount + ' ';
                    strConcat += file.currency + ' ';
                    strConcat += file.type + ' ';
                    strConcat += (filter.category === 'invoices' ? (file.isPaid ? 'Paid' : 'Due') : file.status) + ' ';
                    strConcat += strDate.toLocaleString() + ' ' + strDate.toLocaleString('default', {month: 'long'}) + ' ';
                    strConcat += dueDate.toLocaleString() + ' ' + dueDate.toLocaleString('default', {month: 'long'}) + ' ';
                    strConcat = strConcat.toLowerCase();

                    document.querySelector(`.financial-files__row[data-fileid="${file._id}"]`).style.display =
                        strConcat.indexOf(filter.query.toLowerCase()) >= 0 ? 'table-row' : 'none';
                }
            });

            sortFiles.current.sort((a, b) => {
                switch (factor) {
                    case 'filename':
                        return filter.descendingOrder ?
                            -1 * (a.filename.localeCompare(b.filename)) :
                            a.filename.localeCompare(b.filename);
                    case 'dueDate':
                        return filter.descendingOrder ?
                            new Date(b.dueDate) - new Date(a.dueDate) :
                            new Date(a.dueDate) - new Date(b.dueDate);
                    case 'amount':
                        return filter.descendingOrder ?
                            b.amount - a.amount :
                            a.amount - b.amount;
                    case 'num':
                        return filter.descendingOrder ?
                            -1 * (a.num.localeCompare(b.num)) :
                            a.num.localeCompare(b.num);
                    case 'created_at':
                    default:
                        return filter.descendingOrder ?
                            new Date(b.created_at) - new Date(a.created_at) :
                            new Date(a.created_at) - new Date(b.created_at);
                }
            });

            setFiles(sortFiles.current);
        }
    }, [factor, filter, user._id, projectId]);

    useEffect(() => {
        const getFiles = async () => {
            try {
                const {data} = await axiosAuth.post('/file/fetch', {
                    fileIdList: project.files,
                    factor: localStorage.getItem(`${user._id}_${projectId}_financial_file_factor`) ?? 'created_at',
                    order: (localStorage.getItem(`${user._id}_${projectId}_financial_file_order`) ?? 'asc') === 'asc'
                });
                const fileData = data.filter((item) => {
                    return item.tab === 'financial';
                });
                setFiles(fileData);
            } catch (error) {
                console.log(error)
            }
        }

        getFiles().then();
    }, [project, projectId, user._id]);

    useEffect(() => {
        updateFilesSorting();
    }, [updateFilesSorting, factor, filter]);

    useEffect(() => {
        sortFiles.current = [...files];
    }, [files]);

    useEffect(() => {
        const fileData = files.filter((item) => {
            return item.category === filter.category;
        });
        setFilteredFiles(fileData);
    }, [filter, files]);

    useEffect(() => {
        const {category} = props.file_uploaded;
        if (category !== undefined) setFilter(f => ({...f, category}))
    }, [props.file_uploaded]);

    const handleDownloadedFile = async (event, filePath, filename) => {
        try {
            const {data} = await props.downloadFile(user._id, event.target.dataset.fileid, projectId);

            const newFiles = [...files];
            newFiles.forEach((newFile) => {
                if (newFile._id === data.file._id) newFile.downloadTime = data.file.downloadTime;
            });
            setFiles(newFiles);

            axiosAuth({
                method: "get",
                url: filePath,
                transformResponse: [
                    function (data) {
                        return data;
                    }
                ],
                responseType: 'blob',
            }).then((response) => {
                download(
                    response.data,
                    response.headers["content-type"],
                    filename
                );
            });
        } catch (error) {
            console.log(error)
        }
    }

    const download = (content, mimeType, filename) => {
        const a = window.document.createElement("a"); // Create "a" element
        const blob = new Blob([content], {type: mimeType}); // Create a blob (file-like object)
        const url = URL.createObjectURL(blob); // Create an object URL from blob
        a.setAttribute("href", url); // Set "a" element link
        a.setAttribute("download", filename); // Set download filename
        a.click(); // Start downloading
    }

    const deleteFile = async (event) => {
        const fileIdx = files.findIndex((file) => {
            return file._id === event.target.dataset.fileid;
        });
        const file = files[fileIdx];
        updateFilesSorting();
        await props.deleteFile(project, file);
    }

    const paymentStatus = (isPaid, dueDate) => {
        const dueTimeString = new Date(dueDate);
        const expiredIn = Math.abs(moment().diff(dueTimeString, 'days')) < 1 ?
            Math.abs(moment().diff(dueTimeString, 'hours')) :
            Math.abs(moment().diff(dueTimeString, 'days'));
        const isExpired = moment().isAfter(moment(dueDate));
        const unit = Math.abs(moment().diff(dueTimeString, 'days')) < 1 ? 'hour' : 'day';

        switch (true) {
            case isPaid:
                return <span>Paid - Thank you</span>;
            case !isPaid && !isExpired:
                return <span>Expire in {expiredIn} {unit}{expiredIn > 1 ? 's' : ''}</span>
            case !isPaid && isExpired:
                return (
                    <span>
                        Overdue
                        <span className="text--red">({expiredIn} {unit}{expiredIn > 1 ? 's' : ''})</span>
                    </span>
                );
            default:
                return null;
        }
    }

    return (
        <>
            <SearchFilter placeholder="Search by title, file name or month"
                          handleChange={(e) => setFilter({
                              ...filter,
                              [e.target.name]: e.target.value
                          })}
                          handleClick={() => setFilter({
                              ...filter,
                              'query': ''
                          })}
                          value={filter.query}
            />
            <FilterBar category={filter.category}
                       updateCategory={(category) => setFilter({
                           ...filter,
                           'category': category,
                           'query': ''
                       })}
                       descendingOrder={filter.descendingOrder}
                       initFactor={factor}
                       factorList={factors}
                       updateSorting={() => setFilter({
                           ...filter,
                           'descendingOrder': !filter.descendingOrder
                       })}
                       updateFactor={(e) => setFactor(e.target.value)}
            />
            {props.user.role === "admin" &&
                <Link className="h2 btn--action gap-2 my-2 mt-lg-0 mb-lg-4"
                      to={`/project/${projectId}/fileupload/financial`}
                      state={{background: location, tab: 'financial'}}
                >
                    <i className="material-symbols-outlined text--red icon icon--large">add_circle</i>
                    <span>Upload files</span>
                </Link>
            }
            {filteredFiles.length > 0 ?
                <table className="financial-files__list">
                    <colgroup>
                        <col/>
                        <col width="200px"/>
                        <col/>
                        <col/>
                        <col/>
                        <col/>
                        <col width="70px"/>
                    </colgroup>
                    <tbody>
                    <tr className="financial-files__row d-none d-lg-table-row">
                        <th className="financial-files__header">No.</th>
                        <th className="financial-files__header">{filter.category === 'invoices' ? 'Type' : 'Name'}</th>
                        <th className="financial-files__header">Amount</th>
                        <th className="financial-files__header">{filter.category === 'invoices' ? 'Due date' : 'Date'}</th>
                        <th className="financial-files__header">Status</th>
                        <th className="financial-files__header">Download</th>
                        <th className="financial-files__header"></th>
                    </tr>
                    {
                        filteredFiles.map((file) =>
                            <tr className="financial-files__row" key={file._id} data-fileid={file._id}>
                                <td className="financial-files__cell pt-5 pt-lg-3">
                                    <div className="d-flex d-lg-block justify-content-between align-items-start">
                                        <div className="d-flex">
                                            <label className="financial-files__mobile-label">No.</label>
                                            <div className="financial-files__field-content">{file.num}</div>
                                        </div>
                                        {props.user.role === "admin" &&
                                            <div className="d-lg-none">
                                                <DotDotDot>
                                                    <Link className="dotdotdot__option"
                                                          to={`/project/${project._id}/file/financial/${file._id}/edit`}
                                                          state={{
                                                              background: location,
                                                              tab: 'financial',
                                                              file,
                                                              isEdit: true
                                                          }}
                                                    >Edit file</Link>
                                                    <div className="dotdotdot__option delete"
                                                         data-fileid={file._id}
                                                         onClick={deleteFile}
                                                    >Delete
                                                    </div>
                                                </DotDotDot>
                                            </div>
                                        }
                                    </div>
                                </td>
                                <td className="financial-files__cell">
                                    <div className="d-flex">
                                        <label
                                            className="financial-files__mobile-label">{filter.category === 'invoices' ? 'Type' : 'Name'}</label>
                                        <div className="financial-files__field-content">
                                            {filter.category === 'invoices' ? (file.type !== 'undefined' ? toTitleCase(file.type) : 'N/A') : file.name}
                                        </div>
                                    </div>
                                </td>
                                <td className="financial-files__cell">
                                    <div className="d-flex">
                                        <label className="financial-files__mobile-label">Amount</label>
                                        <div className="financial-files__field-content">
                                            {file.currency}{file.amount.toLocaleString()}
                                        </div>
                                    </div>
                                </td>
                                <td className="financial-files__cell">
                                    <div className="d-flex">
                                        <label className="financial-files__mobile-label">
                                            {filter.category === 'invoices' ? 'Due date' : 'Date'}
                                        </label>
                                        <div className="financial-files__field-content">
                                            <Moment format="DD MMM YYYY">{file.dueDate}</Moment>
                                        </div>
                                    </div>
                                </td>
                                <td className="financial-files__cell">
                                    <div className="d-flex">
                                        <label className="financial-files__mobile-label">Status</label>
                                        <div className="financial-files__field-content">
                                            {filter.category === 'invoices' ?
                                                paymentStatus(file.isPaid, file.dueDate)
                                                :
                                                file.status.charAt(0).toUpperCase() + file.status.substr(1).toLowerCase()
                                            }
                                        </div>
                                    </div>
                                </td>
                                <td className="financial-files__cell pb-5 pb-lg-0">
                                    <div className="d-flex">
                                        <label className="financial-files__mobile-label">Download</label>
                                        <span data-fileid={file._id}
                                              className="text-decoration-underline cursor-pointer"
                                              onClick={(e) => handleDownloadedFile(e, file.path, file.filename)}
                                        >{getFileType(file.filename).toUpperCase()}</span>
                                    </div>
                                </td>
                                {props.user.role === "admin" &&
                                    <td className="financial-files__cell d-none d-lg-table-cell">
                                        <DotDotDot>
                                            <Link className="dotdotdot__option"
                                                  to={`/project/${project._id}/file/financial/${file._id}/edit`}
                                                  state={{
                                                      background: location,
                                                      tab: 'financial',
                                                      file,
                                                      isEdit: true
                                                  }}
                                            >Edit file</Link>
                                            <div className="dotdotdot__option delete"
                                                 data-fileid={file._id}
                                                 onClick={deleteFile}
                                            >Delete</div>
                                        </DotDotDot>
                                    </td>
                                }
                            </tr>
                        )
                    }
                    </tbody>
                </table>
                :
                <div className="financial-files__empty">No files added yet</div>
            }
        </>
    );
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        file_uploaded: state.global.file_uploaded,
    };
};

export default connect(mapStateToProps, {
    deleteFile,
    downloadFile,
})(FinancialFiles);
  