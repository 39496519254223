//React Imports
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Navigate} from "react-router-dom";
import DotDotDot from "../../components/DotDotDot";

//React Redux Imports
import {connect} from "react-redux";
import {assignProject, getArchivedProjects, deleteProject, updateProjectStatus} from "../../store/actions";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {toast} from "react-toast";

function ProjectList(props) {
    const user = JSON.parse(props.user);

    const [update, setUpdate] = useState(true);

    useEffect(() => {
        const getProjects = async (user) => {
            await props.getArchivedProjects(user);
            setUpdate(false);
        };

        if (update) getProjects(user).then();
    }, [update, props, user]);

    if (!user) return <Navigate to="/login" replace/>;

    const projectOnClick = (project) => {
        localStorage.setItem("ph-current-project", JSON.stringify(project));
        props.assignProject(project);
    }

    const deleteProject = (event) => {
        props.deleteProject(user, props.projectList[event.target.dataset.rowidx]);
    }

    const updateStatus = async (projectId, status) => {
        await props.updateProjectStatus(projectId, status)
            .then((res) => {
                toast.success(`Project status has been updated successfully.`);
                setUpdate(true);
            });
    }

    return (
        <>
            <h1 className="main__title mb-5">Archived projects</h1>
            {props?.projectList ?
                <table className="datatable" data-tut="project_table">
                    <colgroup>
                        <col width="65%"/>
                        <col width="30%"/>
                        <col width="5%"/>
                    </colgroup>
                    <tbody>
                    <tr className="datatable__row">
                        <th className="datatable__header">Project name</th>
                        <th className="datatable__header d-none d-md-table-cell">Job No.</th>
                        <th className="datatable__header"></th>
                    </tr>
                    {props?.projectList.length > 0 && props.projectList.map((project, i) =>
                        <tr key={i} className="datatable__row">
                            <td className="datatable__cell">
                                <Link className="datatable__link"
                                      to={`/project/${project._id}/dashboard`}
                                      onClick={() => projectOnClick(project)}
                                >{project.title}</Link>
                            </td>
                            <td className="datatable__cell d-none d-md-table-cell">{project.jobnum}</td>
                            <td className="datatable__cell">
                                {user.role === "admin" &&
                                    <DotDotDot>
                                        <div className="dotdotdot__option update"
                                             data-rowidx={i}
                                             onClick={() => updateStatus(project._id, !project.status)}
                                        >{project.status === 1 ? 'Deactivate' : 'Activate'} project
                                        </div>
                                        <div className="dotdotdot__option delete"
                                             data-rowidx={i}
                                             onClick={deleteProject}
                                        >Delete
                                        </div>
                                    </DotDotDot>
                                }
                            </td>
                        </tr>
                    )}
                    {props?.projectList.length === 0 && <tr>
                        <td colSpan={4} className="text-center p-3">No archived project recently</td>
                    </tr>}
                    </tbody>
                </table>
                :
                <Container fluid>
                    <br/><br/><br/><br/>
                    <Row>
                        <Col md={{span: 6, offset: 6}}>
                            <Spinner animation="border" variant="danger"/>
                        </Col>
                    </Row>
                </Container>
            }
        </>
    );
}

const mapStateToProps = state => {
    return {
        projectList: state.project.projectList,
    };
};

export default connect(mapStateToProps, {
    getArchivedProjects,
    assignProject,
    deleteProject,
    updateProjectStatus
})(ProjectList);
  