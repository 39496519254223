import DotSeparator from "./DotSeparator";
import {convertBytetoMB} from "../utils/Helpers";
import Moment from "react-moment";
import Tag from "./Tag";
import React from "react";
import ProjectFilesOption from "./ProjectFilesOption";
import ProjectFileRemark from "./ProjectFileRemark";

export default function ProjectFileList(props) {
    const {user, location, project, deleteFile, handleDownloadedFile} = props;

    return <>
        {props.files.map((file) =>
            <div className="project-files__item align-items-center gap-4" key={file._id}
                 data-fileid={file._id}>
                <div className="project-files__info w-100">
                    <div className="d-flex flex-column gap-2">
                        <div className="project-files__name position-relative">
                            <span>{file.filename}</span>
                            {file.remarks && <ProjectFileRemark file={file}/>}
                        </div>
                        <div className="d-flex d-md-block justify-content-between align-items-start gap-2">
                            <div className="d-lg-flex flex-wrap project-files__details w-100 align-items-start">
                                <div className="details-field">
                                    {file.downloadTime > 0 ?
                                        <div className="project-files__download-info">
                                            <div className="project-files__download-symbol d-inline-block">
                                                <div className="project-files__download-symbol-arrow"></div>
                                            </div>
                                            <div
                                                className="project-files__download-times d-inline-block ms-1">{` ${file.downloadTime} ${file.downloadTime > 1 ? ' times' : ' time'}`}</div>
                                        </div>
                                        :
                                        <div>Not yet downloaded</div>
                                    }
                                    <DotSeparator/>
                                    <div>{`${convertBytetoMB(file.size)}MB`}</div>
                                    <DotSeparator/>
                                    <div>Sent <Moment format="DD MMM YYYY">{file.created_at}</Moment></div>
                                    {file.tags.length > 0 && <DotSeparator/>}
                                    {
                                        file.tags.map((tag) =>
                                            <Tag key={`tag-${tag}`}
                                                 id={tag}
                                                 handleClick={() => props.handleTagOnClick(tag)}
                                            >{tag}</Tag>
                                        )
                                    }
                                </div>
                                {file.remarks && <div
                                    className="project-files__remarks d-none d-md-block">Remarks: {file.remarks}</div>}
                            </div>
                            <div className="d-md-none">
                                <ProjectFilesOption user={user}
                                                    file={file}
                                                    mobile={true}
                                                    project={project}
                                                    handleDownloadedFile={handleDownloadedFile}
                                                    deleteFile={deleteFile}
                                                    location={location}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <ProjectFilesOption user={user}
                                    file={file}
                                    mobile={false}
                                    project={project}
                                    handleDownloadedFile={handleDownloadedFile}
                                    deleteFile={deleteFile}
                                    location={location}
                />
            </div>
        )}
    </>
}
