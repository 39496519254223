import {useEffect} from "react";
import {Navigate, Outlet} from "react-router-dom";

function ProtectedRoute({user, popup = false, children}) {
    useEffect(() => {
        const appReloaded = sessionStorage.getItem('appReloaded');
        if (!appReloaded) {
            sessionStorage.setItem('appReloaded', true);
            window.location.reload();
        }
    }, []);

    if (!user) return <Navigate to="/login" replace/>;

    return !popup ? (
        <main className="container">
            {children ? children : <Outlet/>}
        </main>
    ) : <Outlet/>;
}

export default ProtectedRoute;