import React, {useCallback, useEffect, useRef, useState} from "react";
import {Link, useOutletContext, useLocation} from "react-router-dom";
import {connect} from "react-redux";

import {updateProject, deleteScope, updateTour} from "../../store/actions";
import {toast} from "react-toast";

import {Form, ProgressBar} from "react-bootstrap";
import Moment from "react-moment";
import Avatar from "../../components/Avatar";
import TextInput from "../../components/TextInput";
import TextButton from "../../components/TextButton";
import Tour from "reactour";
import DotDotDot from "../../components/DotDotDot";

function ProjectDashboard(props) {
    const location = useLocation();
    const {user, project, setProject, members} = useOutletContext();
    const {updateTour} = props;
    const [editMode, setEditMode] = useState(false);
    const [data, setData] = useState({
        jobnum: project.jobnum,
        title: project.title,
        objective: project.objective,
        company: project.company,
        agreedBrief: project.agreedBrief,
    });

    const textAreaRef = useRef(null);

    const [isTourOpen, setIsTourOpen] = useState(!user?.tour?.includes('projectDashboard'));
    const [current, setCurrent] = useState(0);
    const steps = [
        {
            selector: '[data-tut="project__navigation"]',
            content: 'This is the navigation of this project',
            action: () => {
                document.getElementsByTagName('body')[0].style = 'overflow: hidden';
                document.getElementById('root').style = 'pointer-events: none';
            },
            position: 'left',
        },
        {
            selector: '[data-tut="project__dashboard"]',
            content: 'You can view the basic information of this project in Dashboard',
            action: () => {
                document.getElementById('root').style = 'pointer-events: none';
            },
            position: 'left',
        },
        {
            selector: '[data-tut="project__files"]',
            content: 'And these are the files related to this project',
            action: () => {
                document.getElementById('root').style = 'pointer-events: none';
            },
            position: 'left',
        },
        {
            selector: '[data-tut="project__key-member__client"]',
            content: 'You can see the key members of your team by clicking on the avatars',
            action: () => {
                document.getElementById('root').style = 'pointer-events: none';
            },
        },
        {
            selector: '[data-tut="project__key-member__stepworks"]',
            content: 'You can also see the key members of Stepworks',
            action: () => {
                document.getElementById('root').style = 'pointer-events: none';
            },
        },
        {
            content: 'If you have questions, feedback or suggestions when using ProjectHub, please let a Stepworks team know. We’re here to help : )',
            action: () => {
                document.getElementById('root').style = '';
            },
        },
    ]

    const tourNextStep = () => {
        if (current === 1) document.getElementById('nav_container').scrollLeft = 156;
        if (current === 2) document.getElementById('nav_container').scrollLeft = 0;
        setCurrent(current + 1);
    }

    const tourPrevStep = () => {
        if (current === 3) document.getElementById('nav_container').scrollLeft = 156;
        if (current === 2) document.getElementById('nav_container').scrollLeft = 0;
        setCurrent(current - 1);
    }

    const handleTourEnd = () => {
        document.getElementsByTagName('body')[0].style = '';
        setIsTourOpen(false);
    }

    useEffect(() => {
        const updateUserTour = async () => {
            let tour = user['tour'] ?? [];
            tour.push('projectDashboard');
            user['tour'] = tour.filter((value, index, array) => array.indexOf(value) === index);

            await updateTour(user);
        }
        if (!isTourOpen) updateUserTour().then();
    }, [isTourOpen, user, updateTour]);

    const handleProjectUpdate = async () => {
        await props.updateProject(user._id, project._id, data)
            .then((res) => {
                setProject(res);
                toast.success(`Project details have been updated successfully.`);
                setEditMode(false);
            });
    }

    const deleteScope = (event) => {
        const scope = project.scope[event.target.dataset.rowidx];
        props.deleteScope(project._id, scope);
    }

    const handleInputChange = (e) => {
        const input = e.target;
        if (input.name === 'objective') updateTextAreaHeight();
        setData({...data, [input.name]: input.value});
    }

    const updateTextAreaHeight = useCallback(() => {
        textAreaRef.current.style.height = '';
        textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 10 + "px"
    }, []);

    useEffect(() => {
        updateTextAreaHeight();
    }, [updateTextAreaHeight]);

    return (
        <>
            <Tour
                steps={steps}
                isOpen={isTourOpen}
                accentColor="#eb0c38"
                rounded={5}
                onRequestClose={() => setIsTourOpen(false)}
                stepInteraction={false}
                showCloseButton={false}
                closeWithMask={false}
                goToStep={current}
                prevStep={tourPrevStep}
                nextStep={tourNextStep}
                lastStepNextButton={<button
                    className="border-0 bg-transparent fw-bold text--red text-decoration-underline"
                    onClick={handleTourEnd}>OK!</button>}
            />
            <Form className="dashboard">
                {user.role === 'admin' && <div className="mb-2">
                    {
                        !editMode ?
                            <TextButton
                                element={{label: 'Edit', icon: 'edit'}}
                                handleClick={() => setEditMode(true)}
                            /> : <>
                                <TextButton
                                    element={{label: 'Update', icon: 'done'}}
                                    handleClick={handleProjectUpdate}
                                />
                                <TextButton
                                    element={{label: 'Cancel', icon: 'close'}}
                                    handleClick={() => setEditMode(false)}
                                />
                            </>
                    }
                </div>}
                <section className="dashboard__section">
                    <div className="d-flex gap-4">
                        <table className="datatable no-cell-border" id="job_num">
                            <tbody>
                            <tr className="datatable__row">
                                <th className="datatable__header"><label htmlFor="jobnum">Job No.</label></th>
                            </tr>
                            <tr className="datatable__row">
                                <td className="datatable__cell">
                                    <TextInput content={{name: 'jobnum', value: project.jobnum}} editable={editMode}
                                               handleChange={handleInputChange} className="mb-0"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <table className="datatable no-cell-border" id="project_name">
                            <tbody>
                            <tr className="datatable__row">
                                <th className="datatable__header"><label htmlFor="title">Project name</label></th>
                            </tr>
                            <tr className="datatable__row">
                                <td className="datatable__cell">
                                    <TextInput content={{name: 'title', value: project.title}} editable={editMode}
                                               handleChange={handleInputChange} className="mb-0"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="right">
                        {
                            props.user.role === "admin" &&
                            <Link
                                className="btn--action right"
                                to={`/project/${project._id}/add-user`}
                                state={{background: location}}
                            >
                                <TextButton
                                    element={{label: 'Manage users', icon: 'add_circle'}}
                                    icon_first
                                />
                            </Link>
                        }
                    </div>
                    <div className="dashboard__row">
                        <div className="dashboard__left">
                            <table className="datatable no-cell-border" data-tut="project__key-member__client">
                                <tbody>
                                <tr className="datatable__row">
                                    <th className="datatable__header">
                                        Key project team
                                        ({!editMode ?
                                        <>{project.company}</> :
                                        <>
                                            <TextInput content={{name: 'company', value: project.company}}
                                                       editable={true}
                                                       handleChange={handleInputChange}/>
                                        </>
                                    })
                                    </th>
                                </tr>
                                <tr className="datatable__row">
                                    <td className="datatable__cell">
                                        {members.map((member) => {
                                            return member.role === "guest" &&
                                                (
                                                    project.members.some(
                                                        (m) =>
                                                            m.type === "key" &&
                                                            m._id === member._id
                                                    ) ? (
                                                        <Link
                                                            to={`/project/${project._id}/user-information/${member._id}`}
                                                            className="no-text-decoration me-05" key={member._id}
                                                            state={{background: location}}><Avatar
                                                            borderColor={member.color}
                                                            backgroundColor={member.color}
                                                        >
                                                            {member.firstName.substring(0, 1)}
                                                            {member.lastName.substring(0, 1)}
                                                        </Avatar>
                                                        </Link>
                                                    ) : (
                                                        <Link
                                                            to={`/project/${project._id}/user-information/${member._id}`}
                                                            className="no-text-decoration me-05" key={member._id}
                                                            state={{background: location}}><Avatar
                                                            key={member._id}
                                                            borderColor={member.color}
                                                            textColor={member.color}
                                                        >
                                                            {member.firstName.substring(0, 1)}
                                                            {member.lastName.substring(0, 1)}
                                                        </Avatar>
                                                        </Link>
                                                    ));
                                        })}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="dashboard__right">
                            <table className="datatable no-cell-border" data-tut="project__key-member__stepworks">
                                <tbody>
                                <tr className="datatable__row">
                                    <th className="datatable__header">
                                        Key project team (Stepworks)
                                    </th>
                                </tr>
                                <tr className="datatable__row">
                                    <td className="datatable__cell">
                                        {
                                            members.map((member) => {
                                                return member.role !== "guest" &&
                                                    (project.members.some(
                                                        (m) =>
                                                            m.type === "key" &&
                                                            m._id === member._id
                                                    ) ? (
                                                        <Link
                                                            to={`/project/${project._id}/user-information/${member._id}`}
                                                            className="no-text-decoration me-05" key={member._id}
                                                            state={{background: location}}>
                                                            <Avatar
                                                                key={member._id}
                                                                borderColor={member.color}
                                                                backgroundColor={member.color}
                                                            >
                                                                {member.firstName.substring(0, 1)}
                                                                {member.lastName.substring(0, 1)}
                                                            </Avatar>
                                                        </Link>
                                                    ) : (
                                                        <Link
                                                            to={`/project/${project._id}/user-information/${member._id}`}
                                                            className="no-text-decoration me-05" key={member._id}
                                                            state={{background: location}}>
                                                            <Avatar
                                                                key={member._id}
                                                                borderColor={member.color}
                                                                textColor={member.color}
                                                            >
                                                                {member.firstName.substring(0, 1)}
                                                                {member.lastName.substring(0, 1)}
                                                            </Avatar>
                                                        </Link>
                                                    ));
                                            })}
                                        <p className="pt-3">
                                            + team of [designers, layout artists, copywriters,
                                            developers, illustrators, motion graphics]
                                        </p>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
                <section className="dashboard__section">
                    <h2>Project objectives</h2>
                    <table className="datatable no-cell-border">
                        <colgroup>
                            <col width="50%"/>
                            <col width="50%"/>
                        </colgroup>
                        <tbody>
                        <tr className="datatable__row">
                            <th className="datatable__header"></th>
                            <th className="datatable__header"></th>
                        </tr>
                        <tr>
                            <td className="datatable__cell">
                                <TextInput textarea
                                           refNode={textAreaRef}
                                           content={{name: 'objective', value: project.objective}}
                                           editable={editMode}
                                           handleChange={handleInputChange}
                                />

                                {(project.agreedBrief && project.agreedBrief !== '' && !editMode) &&
                                    <a className="d-inline-block mb-5" href={project.agreedBrief} target="_blank"
                                       rel="noreferrer">Agreed brief</a>}
                                {editMode &&
                                    <div>
                                        <Form.Label htmlFor="agreedBrief">Agreed brief</Form.Label>
                                        <input type="text" id="agreedBrief" name="agreedBrief"
                                               defaultValue={project.agreedBrief}
                                               onChange={handleInputChange}/>
                                    </div>
                                }
                            </td>
                            <td className="datatable__cell"></td>
                        </tr>
                        </tbody>
                    </table>
                </section>
                <section className="dashboard__section">
                    <div className="d-flex justify-content-between align-items-end">
                        <h2>Scope of work</h2>
                        <div className="right">
                            {props.user.role === "admin" &&
                                <Link className="btn--action" to={`/project/${project._id}/manage-scope`}
                                      state={{background: location}}>
                                    <TextButton
                                        element={{label: 'Add scope', icon: 'add_circle'}}
                                        icon_first
                                    />
                                </Link>
                            }
                        </div>
                    </div>
                    <table className="datatable no-cell-border no-cell-padding-y">
                        <colgroup>
                            <col width="40%"/>
                            <col width="20%"/>
                            <col width="20%"/>
                            <col width="20%"/>
                            <col width="70px"/>
                        </colgroup>
                        <thead>
                        <tr className="datatable__row">
                            <th className="datatable__header"></th>
                            <th className="datatable__header d-none d-lg-table-cell">Target completion</th>
                            <th className="datatable__header d-none d-lg-table-cell">Current status</th>
                            <th className="datatable__header d-none d-lg-table-cell"></th>
                            <th className="datatable__header"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr className="datatable__row">
                            <td className="datatable__cell">&nbsp;</td>
                            <td className="datatable__cell d-none d-lg-table-cell">&nbsp;</td>
                            <td className="datatable__cell d-none d-lg-table-cell">&nbsp;</td>
                            <td className="datatable__cell d-none d-lg-table-cell">&nbsp;</td>
                            <td className="datatable__cell d-none d-lg-table-cell">&nbsp;</td>
                        </tr>
                        {
                            project.scope.length > 0 ?
                                [...project.scope].sort((a, b) => a.targetDate > b.targetDate ? 1 : -1).map((scopeItem, i) =>
                                    <tr className="datatable__row" key={`scope-${i}`}>
                                        <td className="datatable__cell">
                                            <div className="d-flex justify-content-between gap-2">
                                                <div className="d-flex gap-2">
                                                    <span>&bull;</span>
                                                    <span>{scopeItem.task}</span>
                                                </div>
                                                {props.user.role === "admin" &&
                                                    <div className="d-lg-none">
                                                        <DotDotDot>
                                                            <Link className="dotdotdot__option"
                                                                  to={`/project/${project._id}/scope/${scopeItem._id}/edit`}
                                                                  state={{
                                                                      background: location,
                                                                      isEdit: true,
                                                                      scopeItem
                                                                  }}>Edit</Link>
                                                            <div className="dotdotdot__option delete" data-rowidx={i}
                                                                 onClick={deleteScope}>Delete
                                                            </div>
                                                        </DotDotDot>
                                                    </div>
                                                }
                                            </div>
                                            <div className="d-lg-none ps-3 small text-secondary">
                                                Target completion: <Moment format="DD MMM YYYY">{scopeItem.targetDate}</Moment>
                                            </div>
                                            <div className="d-lg-none px-3 mt-1 mb-3">
                                                <ProgressBar
                                                    variant="danger"
                                                    now={scopeItem.progress}
                                                    label={`${scopeItem.progress}%`}
                                                />
                                            </div>
                                        </td>
                                        <td className="datatable__cell d-none d-lg-table-cell">
                                            <Moment format="DD MMM YYYY">{scopeItem.targetDate}</Moment>
                                        </td>
                                        <td className="datatable__cell d-none d-lg-table-cell pe-3">{scopeItem.status}</td>
                                        <td className="d-none d-lg-table-cell"
                                            style={{verticalAlign: "top", paddingTop: ".5rem"}}>
                                            <ProgressBar
                                                variant="danger"
                                                now={scopeItem.progress}
                                                label={`${scopeItem.progress}%`}
                                            />
                                        </td>

                                        <td className="datatable__cell d-none d-lg-table-cell">
                                            {props.user.role === "admin" &&
                                                <DotDotDot>
                                                    <Link className="dotdotdot__option"
                                                          to={`/project/${project._id}/scope/${scopeItem._id}/edit`}
                                                          state={{
                                                              background: location,
                                                              isEdit: true,
                                                              scopeItem
                                                          }}>Edit</Link>
                                                    <div className="dotdotdot__option delete" data-rowidx={i}
                                                         onClick={deleteScope}>Delete
                                                    </div>
                                                </DotDotDot>
                                            }
                                        </td>
                                    </tr>
                                ) :
                                <tr className="datatable__row">
                                    <td className="datatable__cell" colSpan="5">No task added yet</td>
                                </tr>
                        }
                        </tbody>
                    </table>
                </section>
            </Form>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        projectMembers: state.project.projectMembers,
    };
};

export default connect(mapStateToProps, {updateProject, deleteScope, updateTour})(ProjectDashboard);
