import React, {useEffect, useRef, useState} from "react";

export default function DotDotDot(props) {
    const menuRef = useRef(null);
    const dotRef = useRef(null);
    const [open, setOpen] = useState(false);

    const useOutsideAlerter = (ref) => {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target) && event.target !== dotRef.current) setOpen(false);
            }
            document.addEventListener("mousedown", handleClickOutside);

            return () => document.removeEventListener("mousedown", handleClickOutside);
        }, [ref]);
    }

    useOutsideAlerter(menuRef);

    return (
        <div className="dotdotdot">
            <i className="material-symbols-outlined icon icon--small fw-bold dotdotdot__icon"
               onClick={() => setOpen(!open)}
               ref={dotRef}
            >more_vert</i>
            <div className={`dotdotdot__menu${open ? ' open' : ''}`} ref={menuRef}>
                {props.children}
            </div>
        </div>
    );
}