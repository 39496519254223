import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux';
import userReducer from './reducers/userReducer';
import globalReducer from './reducers/globalReducer';
import projectReducer from './reducers/projectReducer';

let reducers = combineReducers({
    user: userReducer,
    global: globalReducer,
    project: projectReducer,
});

export default configureStore({
    reducer:reducers
})