import { SIGN_IN, SIGN_OUT, GET_USER_TOKEN, GET_USER, RELOAD_USER, UPDATE_USER, REGISTER_NEW_USER } from "../actions/types/user";

const INITIAL_STATE = {
  isSignedIn: false,
  user: {
    firstName: "First Name",
    lastname: "Last Name",
    email: "Email",
    role: "Role",
    telephone: "Telephone",
    projects: [],
    color: "#727272",
    downloadHistory: [],
  },
  token: 0,
  userInfo: {},
  newUser: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        isSignedIn: true,
        user: action.payload.user,
        token: action.payload.token
      };
    case SIGN_OUT:
      return {
        ...state,
        isSignedIn: false,
        user: INITIAL_STATE.user,
        token: INITIAL_STATE.token
      };
    case GET_USER:
      return {
        ...state,
        isSignedIn: true,
        user: action.payload
      };
    case RELOAD_USER:
      return {
        ...state,
        user: action.payload
      };
    case GET_USER_TOKEN:
      return {
        ...state,
        token: action.payload
      };
    case UPDATE_USER:
      return {
        ...state,
        user: action.payload,
      }
    case REGISTER_NEW_USER:
      return {
        ...state,
        newUser: action.payload
      };
    default:
      return state;
  }
};
