import Table from 'react-bootstrap/Table';
import Form from "react-bootstrap/Form";
import React from "react";

function UsersTable(props) {
    const checkUserType = (id) => {
        const member = props.allMembers.find((m) => m._id === id);
        return member && member.type ? member.type : '';
    };

    const findUser = (id) => props.allMembers.some((m) => m._id === id);

    const handleSelection = (e) => props.handleSelection(e)

    let users = props.users;

    if (props.role === "guests") users = users.filter(u => u.role === "guest")
    else if (props.role === "stepworks") users = users.filter(u => u.role !== "guest")

    return (
        <div className="table-responsive">
            <Table size="sm" className="users-table">
                <thead>
                <tr className="d-none d-lg-table-row">
                    <th></th>
                    <th>Type</th>
                    <th>Name</th>
                    <th>Job Title</th>
                    <th>Company</th>
                </tr>
                </thead>
                <tbody>
                {
                    users.length > 0 ? users.filter(u => u.role !== props.role).map((user) => {
                        return (
                            <tr key={user._id} className={!findUser(user._id) ? "disabled" : ""}>
                                <td className="text-end text-lg-start users-table__checkbox">
                                    <div className="d-inline-block mx-2">
                                        <Form.Check
                                            type="checkbox"
                                            name={user._id}
                                            id={user._id}
                                            checked={findUser(user._id)}
                                            value={findUser(user._id)}
                                            onChange={handleSelection}
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex align-items-start">
                                        <label className="users-table__mobile-label">Type</label>
                                        <select defaultValue={checkUserType(user._id)} key={checkUserType(user._id)}
                                                id={user._id} onChange={(e) => props.handleDropdown(e)}>
                                            <option value="">Select</option>
                                            <option value="key">Key</option>
                                            <option value="support">Support</option>
                                        </select>
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex align-items-start">
                                        <label className="users-table__mobile-label">Name</label>
                                        <div className="users-table__field-content">
                                            {user.firstName} {user.lastName}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex align-items-start">
                                        <label className="users-table__mobile-label">Job Title</label>
                                        <div className="users-table__field-content">
                                            {user.title}
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex align-items-start">
                                        <label className="users-table__mobile-label">Company</label>
                                        <div className="users-table__field-content">
                                            {user.company}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        )
                    }) : <tr>
                        <td colSpan="6" className="text-center pt-5">No user yet</td>
                    </tr>
                }
                </tbody>
            </Table>
            <br></br>
        </div>
    )
}

export default UsersTable;