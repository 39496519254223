function TextButton(props) {
    const {element} = props;
    return (
        <div className={`textbtn${props.icon_first ? ' rtl' : ''}`}
             onClick={() => props.handleClick ? props.handleClick() : null}
        >
            {element.label}
            <i className="material-symbols-outlined text--red icon">{element.icon}</i>
        </div>
    )
}

export default TextButton;