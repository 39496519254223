import {FileUploader} from "react-drag-drop-files";

let droppedFileList = [];
const onFileUpload = (files) => {
    droppedFileList = Object.values(files).map((file) => file.name);
}

function FileInput(props) {
    return (
        <div className="file-input">
            <FileUploader handleChange={props.handleChange}
                          name="file"
                          multiple={true}
                          onDrop={onFileUpload}
                          onSelect={onFileUpload}
            />
            <small>{droppedFileList.join(', ')}</small>
        </div>
    )
}

export default FileInput;