//React Imports
import {useEffect, useRef, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {useNavigate} from "react-router-dom";

//React Redux Imports
import {connect} from "react-redux";
import {signOut} from "../store/actions";

//Components Imports
import Avatar from './Avatar';

function Header(props) {
    const location = useLocation();
    const navigate = useNavigate();

    const [color, setColor] = useState("#727272");
    const [open, setOpen] = useState(false);

    const menuRef = useRef(null);
    const btnRef = useRef(null);

    const handleLogout = () => {
        props.signOut();
        navigate("/login");
    };

    let user = JSON.parse(props.user);

    useEffect(() => {
        if (user.color) setColor(user.color)
    }, [user.color])

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target) &&
                    event.target.parentElement && event.target.parentElement.parentElement !== btnRef.current)
                    setOpen(false);
            }
            document.addEventListener("mousedown", handleClickOutside);

            return () => document.removeEventListener("mousedown", handleClickOutside);
        }, [ref]);
    }

    useOutsideAlerter(menuRef);

    return (
        <header className="header">
            <Link className="header__logo"
                  to="/projects"
                  data-tut="homepage_logo"
            >
                <img src="/assets/images/icon-stepworks-mini.svg" alt="Stepworks ProjectHub" width="40" height="40" />
            </Link>
            <div className="header__menu menu">
                <div ref={btnRef}>
                    <Avatar className="menu__btn"
                            handleClick={() => setOpen(!open)}
                            backgroundColor={color}
                    >
                        {user.firstName.substring(0, 1) + user.lastName.substring(0, 1)}
                    </Avatar>
                </div>
                <ul className={`menu__list${open ? ' open' : ''}`}
                    id="avatar_menu"
                    ref={menuRef}
                >
                    {user.role === 'admin' &&
                        <li className="menu__item">
                            <Link className="menu__link" to="/archived">Archived Projects</Link>
                        </li>
                    }
                    {user.role === 'admin' &&
                        <li className="menu__item">
                            <Link className="menu__link" to="/user-list">User list</Link>
                        </li>
                    }
                    <li className="menu__item" id="personal_info" data-tut="avatar_menu">
                        <Link className="menu__link"
                              id="account_settings"
                              to="/account-settings"
                              state={{background: location}}
                        >
                            Account settings
                        </Link>
                    </li>
                    <li className="menu__item mt-1">
                        <div className="menu__link" onClick={handleLogout}>Log out</div>
                    </li>
                </ul>
            </div>
        </header>
    );
}

const mapStateToProps = state => {
    return {};
};

export default connect(mapStateToProps, {
    signOut
})(Header);