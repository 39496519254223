//React Imports
import {useState} from "react";
import {Navigate, useNavigate} from "react-router-dom";

//Axios Imports
import axios from "axios";

function Register({token}) {
    const [data, setData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        role: "",
        password: "",
        // user profile
        company: "",
        title: "",
        telephone: "",
    });

    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleChange = ({currentTarget: input}) => {
        setData({...data, [input.name]: input.value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const url = process.env.REACT_APP_API + "/register";
            const {data: res} = await axios.post(url, data);
            console.log(res);
            navigate("/login");
        } catch (error) {
            if (
                error.response &&
                error.response.status >= 400 &&
                error.response.status <= 500
            ) setError(error.response.data.message);
        }
    };

    return (
        <>
            {
                !token ?
                    <div className="login">
                        <div className="login__left">
                            <img src="/assets/images/img-login.svg" alt="" width="382" height="603"/>
                        </div>
                        <div className="login__right">
                            <img src="/assets/images/logo-stepworks.svg" alt="" width="166" height="30"
                                 className="login__logo"/>
                            <h1 className="h2 login__title">Register</h1>

                            <form action="" onSubmit={handleSubmit}>
                                <input type="text"
                                       name="firstName"
                                       id="firstName"
                                       value={data.firstName}
                                       placeholder="First Name*"
                                       onChange={handleChange}
                                       required
                                />
                                <input type="text"
                                       name="lastName"
                                       id="lastName"
                                       value={data.lastName}
                                       placeholder="Last Name*"
                                       onChange={handleChange}
                                       required
                                />
                                <input type="email"
                                       name="email"
                                       id="email"
                                       value={data.email}
                                       placeholder="Email*"
                                       onChange={handleChange}
                                       required
                                />
                                <input type="text"
                                       name="role"
                                       id="role"
                                       value={data.role}
                                       placeholder="Role*"
                                       onChange={handleChange}
                                       required
                                />
                                <input type="password"
                                       name="password"
                                       value={data.password}
                                       id="password"
                                       placeholder="Password*"
                                       onChange={handleChange}
                                       required
                                />
                                <input type="text"
                                       name="company"
                                       id="company"
                                       value={data.company}
                                       placeholder="Company*"
                                       onChange={handleChange}
                                       required
                                />
                                <input type="text"
                                       name="title"
                                       id="title"
                                       value={data.title}
                                       placeholder="Title*"
                                       onChange={handleChange}
                                       required
                                />
                                <input type="text"
                                       name="telephone"
                                       id="telephone"
                                       value={data.telephone}
                                       placeholder="Telephone*"
                                       onChange={handleChange}
                                       required
                                />
                                <button type="submit" className="btn login__btn">Register</button>
                            </form>
                            {error && <div className="login__error">{error}</div>}
                        </div>
                    </div>
                    :
                    <Navigate to="/"/>
            }
        </>
    );
}

export default Register;
  