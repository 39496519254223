//React Imports
import {useEffect, useState} from "react";
import {Navigate, Outlet, useParams} from "react-router-dom";

//Redux Imports
import {connect} from "react-redux";
import {getMembers, fetchAllUsers, assignProject, fetchUser} from "../store/actions";

//Axios Imports
import axiosAuth from "../axios";

//Component Imports
import Navigation from "../components/Navigation";
import Moment from "react-moment";

function ProjectRoute(props) {
    // removed retrieve projectId from useParam()
    // projectId is lost when modal opens and url changes
    // use localStorage instead
    const {projectId} = useParams();
    const [project, setProject] = useState(localStorage.getItem("ph-current-project") ? JSON.parse(localStorage.getItem("ph-current-project")) : {});
    const user = JSON.parse(props.user);
    const {popup, getMembers, fetchAllUsers, assignProject, fetchUser} = props;

    const [members, setMembers] = useState([]);

    // Get all members
    useEffect(() => {
        const getMembersFn = async () => {
            const response = await getMembers(projectId, project.members);
            setMembers(response);
        };

        getMembersFn().then();
    }, [project, projectId, props.newUser, props.updatedMembers, props.updatedMember, getMembers]);

    // Get specific project
    useEffect(() => {
        const getProjectFn = async () => {
            const {data} = await axiosAuth.post('project/fetchOne', {
                projectId
            });
            setProject(data);
            localStorage.setItem("ph-current-project", JSON.stringify(data));
            await assignProject(data);
        }

        getProjectFn().then();
    }, [projectId,
        props.newUser, props.updatedMembers, props.updatedMember,
        props.file_uploaded, props.file_updated, props.file_deleted,
        props.scope_created, props.scope_updated, props.scope_deleted,
        props.tag_created, assignProject]);

    // Get all users
    useEffect(() => {
        const fetchAllUsersFn = async () => {
            await fetchAllUsers();
        }

        fetchAllUsersFn().then();
    }, [project, projectId, props.newUser, props.updatedMembers, props.updatedMember, fetchAllUsers]);

    // Get specific user
    useEffect(() => {
        const fetchUserFn = async () => {
            await fetchUser(user._id);
        };

        fetchUserFn().then();
    }, [project, props.file_uploaded, props.file_updated, props.file_deleted, props.scope_created, props.scope_updated, props.scope_deleted, props.tag_created, user._id, fetchUser]);

    if (!props.user) return <Navigate to="/login" replace/>;

    return !popup ? (
        <>
            {props.children ? props.children :
                <>
                    <h1 className="main__title">
            <span className="main__remark">
              Last modified <Moment format="DD MMM YYYY">{project.updatedAt}</Moment>
            </span>
                        {project.title}
                    </h1>
                    <Navigation user={user} project={project}/>
                    <Outlet context={{user, project, setProject, members}}/>
                </>
            }
        </>
    ) : <Outlet context={{user, project, setProject, members}}/>;
}

const mapStateToProps = state => {
    return {
        updatedMembers: state.project.updatedMembers,
        updatedMember: state.project.updatedMember,
        scope_created: state.project.scope_created,
        scope_updated: state.project.scope_updated,
        scope_deleted: state.project.scope_deleted,
        tag_created: state.project.tag_created,
        file_uploaded: state.global.file_uploaded,
        file_updated: state.global.file_updated,
        file_deleted: state.global.file_deleted,
        newUser: state.user.newUser,
        project: state.project.project,
    };
};

export default connect(mapStateToProps, {getMembers, fetchAllUsers, assignProject, fetchUser})(ProjectRoute);