//React Imports
import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from "react-router-dom";

//Redux Imports
import {connect} from "react-redux";
import {fetchAllUsers, managingProjectTeam, registerNewUser} from '../../store/actions';

//Components imports
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import RegistrationForm from '../../components/RegistrationForm';
import UsersTable from '../../components/UsersTable';
import {toast} from "react-toast";

function ManageUsers(props) {
    const {projectMembers, project, newUser} = props;
    const navigate = useNavigate();
    const {projectId} = useParams();
    const [allMembers, setAllMembers] = useState([]);
    const [isRegisterNew, setIsRegisterNew] = useState(false);

    const [activeTab, setActiveTab] = useState('all');
    const [users, setUsers] = useState([]);

    useEffect(() => {
        setUsers(props.users);
    }, [props.users]);

    useEffect(() => {
        const memberMap = new Map(project.members.map((m) => [m._id, m.type]));
        const mergedUsersArray = projectMembers.map((u) => ({
            ...u,
            type: memberMap.get(u._id) || "",
        }));
        setAllMembers(mergedUsersArray);
    }, [isRegisterNew, projectMembers, project.members]);

    const handleExisting = () => setIsRegisterNew(!isRegisterNew);

    const handleSelection = (e) => {
        const id = e.target.id;
        const selected = e.target.checked;
        if (selected) {
            setAllMembers((prevMembers) => {
                let selectedMember = users.find((u) => u._id === id);
                selectedMember['emailNotification'] = true;
                return [...prevMembers, selectedMember]
            });
        } else {
            setAllMembers((prevMembers) => prevMembers.filter((u) => u._id !== id));
        }
    };

    const handleDropdown = (e) => {
        const {id, value} = e.target;
        setAllMembers((prevMembers) => {
            const index = prevMembers.findIndex((u) => u._id === id);
            if (index !== -1) {
                return [
                    ...prevMembers.slice(0, index),
                    {...prevMembers[index], type: value},
                    ...prevMembers.slice(index + 1),
                ];
            }
            return prevMembers;
        });
    };

    const handleExistingUsersSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await props.managingProjectTeam(allMembers, project._id)
            if (response) {
                navigate(props.background ? -1 : `/project/${project._id}/dashboard`);
                toast.success(response.data.message);
            }
        } catch (error) {
            if (
                error.response &&
                error.response.status >= 400 &&
                error.response.status <= 500
            ) toast.error(error.response.data.error);
        }
    };

    return (
        <div className={'manage-users' + (props.background ? ' form__container' : '')}>
            {isRegisterNew || newUser ?
                <>
                    {
                        props.background ?
                            <h1>{newUser ? 'Add new' : 'Register'} user</h1>
                            :
                            newUser ?
                                <h1 className="mt-4 pt-2 fw-bold">Add new user</h1>
                                :
                                <h2 className="mt-4 pt-2 fw-bold mb-0">{newUser ? 'Add new' : 'Register'} user</h2>
                    }
                    <RegistrationForm projectId={projectId} handleExisting={handleExisting} newUser={newUser}/>
                </>
                :
                <div>
                    {
                        props.background ?
                            <h1>Manager users</h1>
                            :
                            <h2 className="mt-4 pt-2 fw-bold">Manager users</h2>
                    }
                    <Tabs
                        defaultActiveKey="all"
                        className="mb-3"
                        activeKey={activeTab}
                        onSelect={(k) => setActiveTab(k)}
                    >
                        <Tab eventKey="all" title="All">
                            <UsersTable allMembers={allMembers}
                                        users={users}
                                        handleDropdown={handleDropdown}
                                        handleSelection={handleSelection}
                                        role="all"
                            />
                        </Tab>
                        <Tab eventKey="guests" title="Guests">
                            <UsersTable allMembers={allMembers}
                                        users={users}
                                        handleDropdown={handleDropdown}
                                        handleSelection={handleSelection}
                                        role="guests"
                            />
                        </Tab>
                        <Tab eventKey="stepworks" title="Stepworks">
                            <UsersTable allMembers={allMembers}
                                        users={users}
                                        handleDropdown={handleDropdown}
                                        handleSelection={handleSelection}
                                        role="stepworks"
                            />
                        </Tab>
                    </Tabs>
                    <div className="d-flex flex-wrap align-items-center mt-5">
                        <button type="submit"
                                className="btn login__btn"
                                style={{marginTop: 0}}
                                onClick={handleExistingUsersSubmit}
                        >Update</button>
                        <div className='ms-05'>
                            or
                            <span className="select-existing" onClick={() => handleExisting()}>register new user</span>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

const mapStateToProps = state => {
    return {
        projectMembers: state.project.projectMembers,
        project: state.global.project,
        users: state.global.users
    };
};

export default connect(mapStateToProps, {
    fetchAllUsers,
    managingProjectTeam,
    registerNewUser
})(ManageUsers);