//React Imports
import React, {useState, useRef} from 'react';
import {useNavigate} from "react-router-dom";

//Redux Imports
import {connect} from "react-redux";
import {fetchAllUsers, registerNewUser} from '../store/actions';
import {toast} from "react-toast";

//Other Imports
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

function RegistrationForm(props) {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        role: "guest",
        title: "",
        company: "",
        telephone: "",
        projectId: props.projectId ?? '',
        memberType: "support",
    });

    const registrationForm = useRef(null);

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const fetchAllUsersFn = async () => {
        await fetchAllUsers();
    }

    const handleRegisterSubmit = async (e) => {
        e.preventDefault();
        try {
            let response = await props.registerNewUser(formData);
            if (response) {
                toast.success(response.data.message);
                e.target.reset();
                if (props.newUser) fetchAllUsersFn().then(() => navigate(`/user-list`));
                navigate(`/project/${props.projectId}/dashboard`);
            }
        } catch (error) {
            if (
                error.response &&
                error.response.status >= 400 &&
                error.response.status <= 500
            ) toast.error(error.response.data.message);
        }
    };

    return (
        <div>
            <div className="form__remarks">* Mandatory fields</div>
            <Form ref={registrationForm} onSubmit={handleRegisterSubmit}>
                <Row>
                    <div className="col-lg-6">
                        <Form.Label>First Name *</Form.Label>
                        <Form.Control type="text"
                                      name="firstName"
                                      id="firstName"
                                      value={formData.firstName}
                                      onChange={handleChange}
                                      required
                        />
                    </div>

                    <div className="col-lg-6">
                        <Form.Label>Last Name *</Form.Label>
                        <Form.Control type="text"
                                      name="lastName"
                                      id="lastName"
                                      value={formData.lastName}
                                      onChange={handleChange}
                                      required
                        />

                    </div>
                </Row>
                <Row>
                    <div className="col-lg-6">
                        <Form.Label>Email *</Form.Label>
                        <Form.Control type="email"
                                      name="email"
                                      id="email"
                                      value={formData.email}
                                      onChange={handleChange}
                                      required
                        />
                    </div>

                    <div className="col-lg-6">
                        <Form.Label>Job Title *</Form.Label>
                        <Form.Control type="text"
                                      name="title"
                                      id="title"
                                      value={formData.title}
                                      onChange={handleChange}
                                      required
                        />
                    </div>
                </Row>
                <Row>
                    <div className="col-lg-6">
                        <Form.Label>Telephone</Form.Label>
                        <Form.Control type="text"
                                      name="telephone"
                                      id="telephone"
                                      value={formData.telephone}
                                      onChange={handleChange}
                        />
                    </div>

                    <div className="col-lg-6">
                        <Form.Label>Company *</Form.Label>
                        <Form.Control type="text"
                                      name="company"
                                      id="company"
                                      value={formData.company}
                                      className="login__input"
                                      onChange={handleChange}
                                      required
                        />
                    </div>
                </Row>
                <Row>
                    {!props.newUser &&
                        <div className="col-lg-6 mb-3">
                            <Form.Label>Key contact person on the team?</Form.Label>
                            <Col className="py-2">
                                <Form.Check
                                    type="radio"
                                    label="Yes"
                                    name="memberType"
                                    id="member_type_key"
                                    value="key"
                                    onChange={handleChange}
                                />
                                <Form.Check
                                    type="radio"
                                    label="No"
                                    name="memberType"
                                    id="member_type_support"
                                    value="support"
                                    onChange={handleChange}
                                    defaultChecked={true}
                                />
                            </Col>
                        </div>
                    }

                    <div className="col-lg-6 mb-3">
                        <Form.Label>User's permissions</Form.Label>
                        <Col className="py-2">
                            <Form.Check
                                type="radio"
                                label="Guest"
                                name="role"
                                id="role_guest"
                                value="guest"
                                onChange={handleChange}
                                defaultChecked={true}
                            />
                            <Form.Check
                                type="radio"
                                label="Contributor from Stepworks"
                                name="role"
                                id="role_contributor"
                                value="contributor"
                                onChange={handleChange}
                            />
                            <Form.Check
                                type="radio"
                                label="Administrator (assign only Stepworks AS)"
                                name="role"
                                id="role_admin"
                                value="admin"
                                onChange={handleChange}
                            />
                        </Col>
                    </div>
                </Row>
                <div className="d-flex flex-wrap align-items-center">
                    <button type="submit"
                            className="btn login__btn"
                            style={{marginTop: 0}}
                    >{props.newUser ? 'Submit' : 'Register'}</button>
                    {!props.newUser &&
                        <div className="ms-05">
                            or
                            <span className="select-existing" onClick={props.handleExisting}>select existing user</span>
                        </div>
                    }
                </div>
            </Form>
        </div>
    )
}

const mapStateToProps = state => {
    return {};
};

export default connect(mapStateToProps, {registerNewUser})(RegistrationForm);