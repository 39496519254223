//React Imports
import {useState, useEffect} from "react";
import {Navigate, useNavigate, useSearchParams} from "react-router-dom";

//Axios Imports
import {toast} from 'react-toast';
import axios from "axios";

function ChangePassword({token}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = useState({
        userId: "",
        password: "",
        repeatPassword: "",
        token: searchParams.get("key"),
    });
    const [error, setError] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        // check token is valid and get user info
        const url = process.env.REACT_APP_API + "/auth/validateToken";
        const validateToken = async () => {
            try {
                await axios.post(url, {
                    token: searchParams.get("key"),
                }).then((res) => {
                    setData(d => ({...d, userId: res.data.data.userId}));
                });
            } catch (error) {
                if (
                    error.response &&
                    error.response.status >= 400 &&
                    error.response.status <= 500
                ) navigate("/404");
            }
        }
        validateToken().then();
    }, [searchParams, navigate])

    const handleChange = ({currentTarget: input}) => {
        setData({...data, [input.name]: input.value});
        setError(input.name === 'repeatPassword' && input.value !== data.password ? 'Passwords do not match' : '');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (data.password === data.repeatPassword) {
            setError('');
            try {
                const url = process.env.REACT_APP_API + "/auth/changePassword";
                const {data: res} = await axios.post(url, data);
                console.log(res);
                toast.success(res.message);
                navigate("/login");
            } catch (error) {
                if (
                    error.response &&
                    error.response.status >= 400 &&
                    error.response.status <= 500
                ) {
                    toast.error(error.response.data.message);
                    setError(error.response.data.message);
                }
            }
        } else {
            setError('Passwords do not match');
        }
    };

    return (
        <>
            {
                !token ?
                    <div className="login">
                        <div className="login__left">
                            <img src="/assets/images/img-login.svg"
                                 alt="" width="382" height="603"
                            />
                        </div>
                        <div className="login__right">
                            <img src="/assets/images/logo-stepworks.svg"
                                 alt="" width="166" height="30"
                                 className="login__logo"
                            />
                            <h1 className="h2 login__title">Change password</h1>

                            <form action="" onSubmit={handleSubmit}>
                                <input type="password"
                                       name="password"
                                       id="password"
                                       value={data.password}
                                       className="login__input"
                                       placeholder="Password"
                                       onChange={handleChange}
                                       required
                                />
                                <input type="password"
                                       name="repeatPassword"
                                       id="repeatPassword"
                                       value={data.repeatPassword}
                                       className="login__input"
                                       placeholder="Confirm Password"
                                       onChange={handleChange}
                                       required
                                />
                                <button type="submit" className="btn login__btn">Change my password</button>
                            </form>
                            {error && <div className="login__error">{error}</div>}
                        </div>
                    </div>
                    :
                    <Navigate to="/"/>
            }
        </>
    );
}

export default ChangePassword;
  