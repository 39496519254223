import DotDotDot from "./DotDotDot";
import {Link} from "react-router-dom";

export default function ProjectFilesOption(props) {
    const {file, user, mobile, location, project, deleteFile, handleDownloadedFile} = props;

    return (
        <div className={`project-files__options ${mobile ? 'pt-1 d-md-none' : 'd-none d-md-flex'}`}>
            <div className="project cursor-pointer"
                 data-fileid={file._id}
                 onClick={(e) => handleDownloadedFile(e, file.path, file.filename)}
            >
                <div className="project-files__download">
                    <div className="project-files__download-arrow"></div>
                </div>
            </div>
            {user.role === "admin" &&
                <div className={mobile ? 'ps-2' : ''}>
                    <DotDotDot>
                        <Link className="dotdotdot__option"
                              to={`/project/${project._id}/file/project/${file._id}/edit`}
                              state={{background: location, tab: 'project', file, isEdit: true}}
                        >Edit file</Link>

                        <div className="dotdotdot__option delete" data-fileid={file._id}
                             onClick={(e) => deleteFile(e)}
                        >Delete</div>
                    </DotDotDot>
                </div>
            }
        </div>
    );
}