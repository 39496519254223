//React imports
import React, {useState, useEffect} from 'react';

//Redux imports
import {connect} from "react-redux";
import {useParams, useNavigate} from "react-router-dom";
import {signOut, fetchUser, updateUser, getUser, updatePassword, requestEdit, fetchAllUsers} from "../../store/actions";

//components import
import {toast} from "react-toast";
import ProfileImage from "../../components/ProfileImage";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

function UserPopup(props) {
    const navigate = useNavigate();
    const {userId} = useParams();

    const currentUser = userId === undefined || userId === JSON.parse(props.user)._id;
    const user = JSON.parse(props.user);

    const [allowEdit, setAllowEdit] = useState(false);
    const [userDetails, setUserDetails] = useState(user);

    const [edit, setEdit] = useState(false);
    const [editValue, setEditValue] = useState('');

    const [activeTab, setActiveTab] = useState('profile');

    const [pwdDetails, setPwdDetails] = useState({
        currentPassword: '',
        newPassword: '',
        repeatPassword: '',
    });
    const [pwdError, setPwdError] = useState('');

    useEffect(() => {
        if (userId) {
            props.fetchUser(userId, false).then((res) => {
                setUserDetails(res);
            });
        }
    }, [userId, props])

    useEffect(() => {
        setPwdError(pwdDetails.repeatPassword !== pwdDetails.newPassword ? 'Passwords do not match' : '');
    }, [pwdDetails.repeatPassword, pwdDetails.newPassword]);

    const handleLogout = () => {
        props.signOut();
        navigate("/login");
    };

    const handleEditRequest = async (e) => {
        setEdit(!edit);
        try {
            const response = await props.requestEdit(user, editValue);

            if (response) toast.success(response.data)
            setEditValue('');
        } catch (error) {
            if (
                error.response &&
                error.response.status >= 400 &&
                error.response.status <= 500
            ) toast.error(error.response.data.message);
        }
    };

    const handleChange = ({currentTarget: input}) => setUserDetails({...userDetails, [input.name]: input.value});
    const handlePasswordChange = ({currentTarget: input}) => setPwdDetails({...pwdDetails, [input.name]: input.value});

    const fetchAllUsersFn = async () => {
        await fetchAllUsers();
    }

    const handleUserUpdate = async () => {
        try {
            const response = await props.updateUser(userDetails, currentUser);
            props.getUser();
            toast.success(response.data.message);
            setAllowEdit(!allowEdit);

            fetchAllUsersFn().then();
        } catch (error) {
            if (
                error.response &&
                error.response.status >= 400 &&
                error.response.status <= 500
            ) toast.error(error.response.data.message);
        }
    };

    const handleTabClick = ({currentTarget}) => setActiveTab(currentTarget.dataset.tab)

    const handlePasswordUpdate = async (e) => {
        // check repeat password
        try {
            const response = await props.updatePassword(pwdDetails, user);
            toast.success(response.data.message);
            setActiveTab('profile');
        } catch (error) {
            if (
                error.response &&
                error.response.status >= 400 &&
                error.response.status <= 500
            ) toast.error(error.response.data.message);
        }
    };

    return (
        <div className="account-settings" data-tut="account_setting">
            <div className="account-settings__left">
                <h2 className="fw-bold">{props.title}</h2>
                {
                    props.show_info === 'account_settings' &&
                    <div className="overflow-auto w-100">
                        <div className="max-content">
                            <ul className="account-settings__left-list">
                                <li className={`account-settings__left-list-item ${activeTab === 'profile' ? 'active' : ''}`}
                                    onClick={handleTabClick} data-tab="profile"
                                >Profile
                                </li>
                                <li className={`account-settings__left-list-item ${activeTab === 'change-password' ? 'active' : ''}`}
                                    onClick={handleTabClick} data-tab="change-password"
                                >Change password
                                </li>
                                <li className="account-settings__left-list-item">
                                    <span onClick={handleLogout}>Logout</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                }
            </div>
            <div className="account-settings__right">
                <div className='row align-items-end mb-30'>
                    <div className='col-6'>
                        <ProfileImage user={user} targetUser={userDetails} currentUser={currentUser}/>
                    </div>
                    {
                        activeTab === 'profile' &&
                        <div className='col-6'>
                            {user.role === 'admin' && (
                                <div className='edit-user-button' onClick={() => setAllowEdit(!allowEdit)}>
                                    {allowEdit ? 'Cancel edit' : 'Edit details'}
                                    <i className="material-symbols-outlined icon icon--small text--red">edit</i>
                                </div>
                            )}
                        </div>
                    }
                </div>
                {
                    activeTab === 'profile' &&
                    <div className={`user-details${allowEdit ? ' edit' : ''}`}>
                        <div className="row">
                            <div className="col-lg-6 mb-30">
                                <Form.Label htmlFor="firstName">First name</Form.Label>
                                <Form.Control
                                    id="firstName"
                                    name="firstName"
                                    value={userDetails.firstName}
                                    onChange={handleChange}
                                    readOnly={!allowEdit}
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <Form.Label htmlFor="lastName">Last name</Form.Label>
                                <Form.Control id="lastName"
                                              name="lastName"
                                              value={userDetails.lastName}
                                              onChange={handleChange}
                                              readOnly={!allowEdit}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 mb-30">
                                <Form.Label htmlFor="title">Title</Form.Label>
                                <Form.Control id="title"
                                              name="title"
                                              value={userDetails.title}
                                              onChange={handleChange}
                                              readOnly={!allowEdit}
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <Form.Label htmlFor="company">Company</Form.Label>
                                <Form.Control id="company"
                                              name="company"
                                              value={userDetails.company}
                                              onChange={handleChange}
                                              readOnly={!allowEdit}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 mb-30">
                                <Form.Label htmlFor="email">Email</Form.Label>
                                <Form.Control id="email"
                                              name="email"
                                              type="email"
                                              value={userDetails.email}
                                              onChange={handleChange}
                                              readOnly={!allowEdit}
                                />
                            </div>
                            <div className="col-lg-6 mb-30">
                                <Form.Label htmlFor="telephone">Telephone</Form.Label>
                                <Form.Control id="telephone"
                                              name="telephone"
                                              value={userDetails.telephone}
                                              onChange={handleChange}
                                              readOnly={!allowEdit}
                                />
                            </div>
                        </div>
                        {
                            user.role === 'admin' &&
                            <div className="row user-permissions">
                                <div className="col-lg-6 mb-30">
                                    <Form.Label>User's permissions</Form.Label>
                                    {allowEdit ?
                                        <Col className="pb-2">
                                            <Form.Check type="radio"
                                                        label="Guest"
                                                        name="role"
                                                        id="role_guest"
                                                        value="guest"
                                                        onChange={handleChange}
                                                        defaultChecked={userDetails.role === 'guest'}
                                            />
                                            <Form.Check type="radio"
                                                        label="Contributor from Stepworks"
                                                        name="role"
                                                        id="role_contributor"
                                                        value="contributor"
                                                        onChange={handleChange}
                                                        defaultChecked={userDetails.role === 'contributor'}
                                            />
                                            <Form.Check type="radio"
                                                        label="Administrator"
                                                        name="role"
                                                        id="role_admin"
                                                        value="admin"
                                                        onChange={handleChange}
                                                        defaultChecked={userDetails.role === 'admin'}
                                            />
                                        </Col> :
                                        <Form.Control name="role"
                                                      className="text-capitalize"
                                                      value={userDetails.role}
                                                      readOnly={true}
                                        />
                                    }
                                </div>
                            </div>
                        }
                        {allowEdit && <button className='btn btn--small' onClick={handleUserUpdate}>Update</button>}
                    </div>
                }
                {
                    activeTab === 'change-password' &&
                    <div className='password-block'>
                        <div className="row">
                            <div className="col-lg-4 mb-30">
                                <Form.Label htmlFor="currentPassword">Current password</Form.Label>
                                <Form.Control id="currentPassword"
                                              name="currentPassword"
                                              value={userDetails.currentPassword}
                                              onChange={handlePasswordChange}
                                              type="password"
                                              required
                                />
                            </div>
                            <div className="col-lg-4 mb-30">
                                <Form.Label htmlFor="newPassword">New password</Form.Label>
                                <Form.Control id="newPassword"
                                              name="newPassword"
                                              value={userDetails.newPassword}
                                              onChange={handlePasswordChange}
                                              type="password"
                                              required
                                />
                            </div>
                            <div className="col-lg-4 mb-30">
                                <Form.Label htmlFor="repeatPassword">Repeat password</Form.Label>
                                <Form.Control id="repeatPassword"
                                              name="repeatPassword"
                                              value={userDetails.repeatPassword}
                                              onChange={handlePasswordChange}
                                              type="password"
                                              required
                                />
                                {pwdError.length > 0 && <Form.Text>{pwdError}</Form.Text>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <button className='btn btn--small mt-0' onClick={handlePasswordUpdate}>
                                    Update Password
                                </button>
                            </div>
                        </div>
                    </div>
                }
                {props.show_info === 'account_settings' && !allowEdit && activeTab === 'profile' &&
                    <div className="request-edit">
                        <span className="link" onClick={() => setEdit(!edit)}>Request an edit</span>
                        {edit && (
                            <div className='edit-area'>
                                <textarea placeholder="Enter your edits here"
                                          className="form-control"
                                          name="edits"
                                          value={editValue}
                                          onChange={(e) => setEditValue(e.target.value)}
                                ></textarea>
                                <button className="btn" onClick={handleEditRequest}>Post</button>
                            </div>
                        )}
                    </div>
                }
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {};
};

export default connect(mapStateToProps, {
    fetchUser,
    getUser,
    requestEdit,
    signOut,
    updatePassword,
    updateUser,
})(UserPopup);