//React Imports
import {Routes, Route, useLocation, Navigate, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {createBrowserHistory} from 'history';

//React Redux Imports
import {connect} from "react-redux";
import {getUser} from "./store/actions";
import {getUserToken} from "./store/actions";

//Axios Imports
import SetupInterceptors from './axios/interceptor';

/* 
*
COMPONENTS
*
*/
import Login from "./pages/Login";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";
import ChangePassword from "./pages/ChangePassword";
import NotFound from "./pages/NotFound";
import ProjectList from "./pages/project/ProjectList";
import ProjectDashboard from "./pages/project/ProjectDashboard";
import ProjectFiles from "./pages/project/ProjectFiles";
import FinancialFiles from "./pages/project/FinancialFiles";
import ProjectNew from "./pages/popup/ProjectNew";
import FileManager from "./pages/popup/FileManager";
import UserPopup from "./pages/popup/UserPopup";
import ManageUsers from "./pages/popup/ManageUsers";
import ScopeManager from "./pages/popup/ScopeManager";
import ProjectRoute from "./utils/ProjectRoute";
import ProtectedRoute from "./utils/ProtectedRoute";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ModalWrapper from "./components/ModalWrapper";

import {ToastContainer} from "react-toast";
import UserList from "./pages/users/UserList";
import ArchivedProjectList from "./pages/project/ArchivedProjectList";

function App(props) {
    const navigate = useNavigate();
    const location = useLocation();

    const history = createBrowserHistory();
    SetupInterceptors(history);

    let user = localStorage.getItem("ph-user");
    let token = localStorage.getItem("ph-token");

    let {getUser, getUserToken} = props;

    useEffect(() => {
        const userCheck = async () => {
            if (!getUserToken()) navigate('/login');

            await getUser();
        };

        userCheck().then();

        const {navigator} = window;

        if (navigator.userAgent.indexOf('Mac OS X') !== -1)
            document.querySelector('body').classList.add('mac-os');
    }, [navigate, getUser, getUserToken]);

    const background = location.state && location.state.background;

    return (
        <>
            {token && <Header user={user}/>}
            <Routes location={background || location}>
                <Route>
                    <Route path="/login" element={<Login token={token}/>}/>
                    <Route path="/register" element={<Register token={token}/>}/>
                    <Route path="/forgot-password" element={<ForgotPassword token={token}/>}/>
                    <Route path="/change-password" element={<ChangePassword token={token}/>}/>
                    <Route element={<ProtectedRoute user={user}/>}>
                        <Route path="/projects" element={<ProjectList user={user}/>}/>
                        <Route path="/user-list" element={<UserList user={user}/>}/>
                        <Route path="/archived" element={<ArchivedProjectList user={user}/>}/>
                        <Route element={<ProjectRoute user={user}/>}>
                            <Route path="/project/:projectId/dashboard" element={<ProjectDashboard/>}/>
                            <Route path="/project/:projectId/project-files" element={<ProjectFiles/>}/>
                            <Route path="/project/:projectId/financial-files" element={<FinancialFiles/>}/>
                        </Route>
                    </Route>
                </Route>

                <Route path="/" element={<Navigate to="/projects"/>}/>
                <Route path="*" element={<NotFound/>}/>

                <Route path="/account-settings" element={null}/>
                <Route path="/user-information/:userId" element={null}/>
                <Route path="/user/new" element={null}/>
                <Route path="/project/new" element={null}/>
                <Route path="/project/:projectId/user-information/:userId" element={null}/>
                <Route path="/project/:projectId/add-user" element={null}/>
                <Route path="/project/:projectId/manage-scope" element={null}/>
                <Route path="/project/:projectId/fileupload/financial" element={null}/>
                <Route path="/project/:projectId/fileupload/project" element={null}/>
                <Route path="/project/:projectId/file/:fileId/financial/edit" element={null}/>
                <Route path="/project/:projectId/file/:fileId/project/edit" element={null}/>
                <Route path="/project/:projectId/scope/:scopeId/edit" element={null}/>
            </Routes>
            <Routes>
                <Route path="/login" element={null}/>
                <Route path="/register" element={null}/>
                <Route path="/forgot-password" element={null}/>
                <Route path="/change-password" element={null}/>
                <Route element={<ProtectedRoute user={user} popup={background}/>}>
                    <Route path="/projects" element={null}/>
                    <Route path="/project/:projectId/dashboard" element={null}/>
                    <Route path="/project/:projectId/project-files" element={null}/>
                    <Route path="/project/:projectId/financial-files" element={null}/>
                    <Route path="/user-list" element={null}/>
                    <Route path="/archived" element={null}/>

                    <Route path="/account-settings" element={<ModalWrapper background={background}
                                                                           content={<UserPopup user={user}
                                                                                               show_info='account_settings'
                                                                                               title='Account Settings'/>}/>}/>
                    <Route path="/user-information/:userId" element={<ModalWrapper background={background}
                                                                                   content={<UserPopup user={user}
                                                                                                       title='User Information'/>}/>}/>

                    <Route path="/project/new" element={<ModalWrapper background={background}
                                                                      content={<ProjectNew background={background}
                                                                                           user={user}/>}/>}/>
                    <Route path="/user/new" element={<ModalWrapper background={background}
                                                                   content={<ManageUsers background={background}
                                                                                         newUser={true}/>}/>}/>

                    <Route element={<ProjectRoute user={user} popup={background}/>}>
                        <Route path="/project/:projectId/user-information/:userId"
                               element={<ModalWrapper background={background}
                                                      content={<UserPopup user={user} title='User Information'/>}/>}/>
                        <Route path="/project/:projectId/add-user" element={<ModalWrapper background={background}
                                                                                          content={<ManageUsers
                                                                                              background={background}/>}/>}/>
                        <Route path="/project/:projectId/manage-scope" element={<ModalWrapper background={background}
                                                                                              content={<ScopeManager
                                                                                                  background={background}
                                                                                                  isEdit={false}
                                                                                                  newUser={false}/>}/>}/>
                        <Route path="/project/:projectId/fileupload/project"
                               element={<ModalWrapper background={background}
                                                      content={<FileManager background={background} isEdit={false}
                                                                            tab="project"/>}/>}/>
                        <Route path="/project/:projectId/fileupload/financial"
                               element={<ModalWrapper background={background}
                                                      content={<FileManager background={background} isEdit={false}
                                                                            tab="financial"/>}/>}/>
                        <Route path="/project/:projectId/file/project/:fileId/edit"
                               element={<ModalWrapper background={background}
                                                      content={<FileManager background={background} isEdit={true}
                                                                            tab="project"/>}/>}/>
                        <Route path="/project/:projectId/file/financial/:fileId/edit"
                               element={<ModalWrapper background={background}
                                                      content={<FileManager background={background} isEdit={true}
                                                                            tab="financial"/>}/>}/>
                        <Route path="/project/:projectId/scope/:scopeId/edit"
                               element={<ModalWrapper background={background}
                                                      content={<ScopeManager background={background}
                                                                             isEdit={true}/>}/>}/>
                    </Route>
                </Route>
            </Routes>
            {token && <Footer/>}
            <ToastContainer delay={3000}/>
        </>
    );
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
    };
};

export default connect(mapStateToProps, {
    getUser,
    getUserToken,
})(App);
