import React, {useEffect, useRef, useState} from "react";

export default function ProjectFileRemark(props) {
    const [open, setOpen] = useState(false);
    const remarksRef = useRef(null);
    const btnRef = useRef(null);

    const useOutsideAlerter = (ref) => {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target) && event.target !== btnRef.current) setOpen(false);
            }
            document.addEventListener("mousedown", handleClickOutside);

            return () => document.removeEventListener("mousedown", handleClickOutside);
        }, [ref]);
    }

    useOutsideAlerter(remarksRef);

    return (
        <>
            <div className="d-inline-block d-md-none project-files__mobile-remarks__btn cursor-pointer"
                 onClick={() => setOpen(!open)} ref={btnRef}
            >
                <i className="material-symbols-outlined icon icon--small fw-bold">description</i>
                Remarks
            </div>
            <div className={`project-files__mobile-remarks${open ? ' open' : ''} d-md-none`}
                 ref={remarksRef}
            >{props.file.remarks}</div>
        </>
    );
}