//React Imports
import React, {useEffect, useState, useCallback} from 'react';
import {useLocation, useNavigate, useOutletContext, useParams} from "react-router-dom";

//Redux Imports
import {connect} from "react-redux";
import {uploadFile, updateFile, createTag, createTerm, fetchUser, uploadFileInfo} from '../../store/actions';

//Component imports
import FileInput from '../../components/FileInput';
import DatePicker from "react-datepicker";
import {toast} from 'react-toast';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import axios from "axios";
import axiosAuth from '../../axios';
import "react-datepicker/dist/react-datepicker.css";
import {getFileName, getFileType, buildRSDataFormat} from '../../utils/Helpers';
import Form from "react-bootstrap/Form";
import {v4 as uuid} from 'uuid';

function FileManager(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const {project} = useOutletContext();
    const {fileId, projectId} = useParams();
    const {file: fileItem} = location.state || {};
    const editMode = props.isEdit && fileId;
    const [file, setFile] = useState(fileItem !== undefined ? fileItem : false);
    const emptyFile = !file || file.data === null;
    const [uploadedFiles, setUploadedFiles] = useState({});
    const chunkSize = 1024 * 1024 * 2; // 2MB per chunk
    const [data, setData] = useState({
        filename: '',
        name: '',
        category: '',
        num: '',
        type: '',
        currency: '',
        amount: (0).toFixed(2),
        dueDate: new Date(),
        status: '',
        isPaid: false,
        remarks: '',
    });
    const [currencyExchange, setCurrencyExchange] = useState(editMode && !emptyFile && file.currency !== "undefined" ? {original: data.currency} : []);
    const [tags, setTags] = useState([]);
    const [terms, setTerms] = useState([]);
    const [loading, setLoading] = useState(false);
    const suggestions = project.tags !== undefined ? project.tags.map((t) => buildRSDataFormat(t)) : [];
    const fileExtRe = /(?:\.([^.]+))?$/;

    const category = [
        {value: 'invoices', label: 'Invoices'},
        {value: 'quotes', label: 'Quotes'}
    ]

    const status = [
        {value: 'pending for approval', label: 'Pending for approval'},
        {value: 'submitted', label: 'Submitted'},
        {value: 'revised', label: 'Revised'},
        {value: 'cancelled', label: 'Cancelled'},
    ]

    // TODO: Update the value depends on which API we are going to use
    const currency = [
        {value: 'HKD', label: 'HKD'},
        {value: 'GBP', label: 'GBP'},
        {value: 'CNY', label: 'RMB'},
        {value: 'SGD', label: 'SGD'},
        {value: 'USD', label: 'USD'},
    ];

    const getTerms = useCallback(async function () {
        const {data} = await axiosAuth.post('/term/fetch', {
            type: 'financial_file_type'
        });

        setTerms([...data.map((t) => buildRSDataFormat(t.term))]);
    }, []);

    useEffect(() => {
        if (editMode && emptyFile) {
            const getFile = async () => {
                const data = await axiosAuth.post('/file/fetchOne', {
                    fileId: fileId
                });
                setFile(data.data);
            }
            getFile().then();
        }
    }, [fileId, editMode, emptyFile]);

    useEffect(() => {
        if (file) {
            setData({
                filename: getFileName(file.filename),
                name: file.name,
                category: file.category !== "undefined" ? buildRSDataFormat(file.category) : '',
                num: file.num,
                type: file.type !== "undefined" ? buildRSDataFormat(file.type) : '',
                currency: file.currency !== "undefined" ? buildRSDataFormat(file.currency, true) : '',
                amount: file.amount.toFixed(2),
                dueDate: new Date(file.dueDate),
                status: file.status !== "undefined" ? buildRSDataFormat(file.status) : '',
                isPaid: file.isPaid,
                remarks: file.remarks,
            });
            setCurrencyExchange(file.currency !== "undefined" ? {original: file.currency} : []);
            setTags(file.tags.length > 0 ? file.tags.map((t) => buildRSDataFormat(t)) : []);
        }
    }, [file]);

    useEffect(() => {
        getTerms().then();
    }, [getTerms]);

    // form handlers
    const handleChange = ({currentTarget: input}) => {
        setData({...data, [input.name]: input.value});
    };

    const handleDropdownChange = useCallback(async (selectedOptions, event) => {
        if (event.name === 'type') {
            await props.createTerm(selectedOptions.value, 'financial_file_type')
                .catch((error) => {
                    if (error.response && error.response.status >= 400 && error.response.status <= 500) toast.error(error.message);
                });
            getTerms().then();
        }

        if (event.name === 'currency' && data.currency && data.currency !== 'undefined') setCurrencyExchange({
            original: data.currency,
            new: selectedOptions
        });

        setData({...data, [event.name]: selectedOptions});
    }, [getTerms, data, props]);

    useEffect(() => {
        if (currencyExchange.original && currencyExchange.new) {
            // TODO: Can do research on getting a better api afterwards, this is a free one on the github
            axios.get(`https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/${currencyExchange.original.value.toLowerCase()}.json`)
                .then((res) => {
                    setData(d => ({
                        ...d,
                        amount: (res.data[currencyExchange.original.value.toLowerCase()][currencyExchange.new.value.toLowerCase()] * d.amount).toFixed(2)
                    }));
                })
        }
    }, [currencyExchange]);

    const handleTagChange = useCallback(async (selectedTags, newTag) => {
        const tag = newTag.option;
        if (tag) {
            await props.createTag(project._id, tag.value)
                .catch((error) => {
                    if (error.response && error.response.status >= 400 && error.response.status <= 500) toast.error(error.message);
                });
            await props.createTerm(tag.value, 'project_file_tag')
                .catch((error) => {
                    if (error.response && error.response.status >= 400 && error.response.status <= 500) toast.error(error.message);
                });
        }
        setTags(selectedTags);
    }, [project._id, props]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!editMode && uploadedFiles) {
            if (!Object.keys(uploadedFiles).length) {
                toast.error('Please upload at least one file');
                return null;
            }

            setLoading(true);
            let fileArray = [];

            for (let i = 0; i < uploadedFiles.length; i++) {
                const totalChunks = Math.ceil(uploadedFiles[i].size / chunkSize);
                let startByte = 0;
                fileArray.push(uuid() + '.' + fileExtRe.exec(uploadedFiles[i].name)[1]);

                for (let j = 1; j <= totalChunks; j++) {
                    const endByte = Math.min(startByte + chunkSize, uploadedFiles[i].size);
                    const chunk = uploadedFiles[i].slice(startByte, endByte);
                    const fileData = new FormData();
                    fileData.append('file', chunk);
                    fileData.append('projectId', projectId);
                    fileData.append('name', fileArray[i]);
                    fileData.append('totalChunks', totalChunks);
                    fileData.append('currentChunk', j);

                    await props.uploadFile(fileData, j, totalChunks);

                    startByte = endByte;
                }
            }

            const formData = new FormData();
            formData.append('tab', props.tab);
            formData.append('category', data.category.value);
            formData.append('num', data.num);
            formData.append('name', data.name);
            formData.append('type', data.type.value);
            formData.append('currency', data.currency.value);
            formData.append('amount', data.amount);
            formData.append('dueDate', data.dueDate);
            formData.append('status', data.status.value);
            formData.append('isPaid', data.isPaid);
            formData.append('tags', JSON.stringify(Object.values(tags).map((t) => t.value)));
            formData.append('remarks', data.remarks);
            let fileCount = 0;

            Object.values(uploadedFiles).forEach((file) => {
                formData.append('files[]', JSON.stringify({
                    name: file.name,
                    path: fileArray[fileCount],
                    size: file.size
                }));
                fileCount++;
            });
            formData.append('projectId', projectId);

            await props.uploadFileInfo(formData)
                .then((response) => {
                    if (response.status === 201) {
                        setLoading(false);
                        toast.success(response.data.message);
                        navigate(`/project/${projectId}/${props.tab}-files`);
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status >= 400 && error.response.status <= 500) {
                        setLoading(false);
                        toast.error(error.message);
                    }
                });
        } else {
            const fileData = {
                data: {
                    category: data.category.value,
                    num: data.num,
                    name: data.name,
                    type: data.type.value,
                    currency: data.currency.value,
                    amount: data.amount,
                    dueDate: data.dueDate,
                    status: data.status.value,
                    isPaid: data.isPaid,
                    tags: Object.values(tags).map((t) => t.value),
                    remarks: data.remarks,
                    filename: `${data.filename}.${getFileType(file.filename)}`,
                },
                fileId: file._id,
            }

            await props.updateFile(fileData)
                .then((response) => {
                    if (response.status === 201) {
                        toast.success(response.data.message);
                        navigate(props.background ? -1 : `/project/${project.id}/${props.tab}-files`);
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status >= 400 && error.response.status <= 500) toast.error(error.message);
                });
        }
    }

    return (
        <>
            {props.background &&
                <div className={`modal__cover${loading ? ' show' : ''}`}>
                    <div className="w-100 text-center">
                        {props.uploadProgress}% uploaded
                        <div className="upload-progress">
                            <div className="upload-progress-bar" style={{width: ((props.uploadProgress ?? 0) + '%')}}></div>
                        </div>
                    </div>
                </div>
            }
            <div className={props.background && 'form__container'}>
                {
                    props.background ?
                        <h1>{`${editMode ? 'Update' : 'Upload'} ${props.tab} file`}</h1> :
                        <h2 className="mt-4 pt-2 fw-bold">{`${editMode ? 'Update' : 'Upload'} ${props.tab} file`}</h2>
                }
                <div className="form__remarks">* Mandatory fields</div>
                <form action="" onSubmit={handleSubmit}>
                    {!editMode ?
                        <FileInput handleChange={(files) => setUploadedFiles(files)}/>
                        :
                        (props.tab !== 'financial' ?
                                <>
                                    <Form.Label htmlFor="filename">File name</Form.Label>
                                    <input type="text"
                                           id="filename"
                                           name="filename"
                                           value={data.filename}
                                           onChange={handleChange}
                                    />
                                </>
                                :
                                <div className="mb-4 d-flex gap-2">
                                    <Form.Label>File</Form.Label>
                                    <a href={`${process.env.REACT_APP_API}${file.path}`}
                                       target="_blank"
                                       rel="noreferrer"
                                       download={file.filename}
                                       data-fileid={file._id}
                                    >{file.filename}</a>
                                </div>
                        )
                    }
                    {props.tab === 'financial' ? (
                        <div>
                            <div className="form__cell form__cell--2col">
                                <Form.Label htmlFor="category">Category *</Form.Label>
                                <Select id="category"
                                        name="category"
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        value={data.category}
                                        options={category}
                                        onChange={handleDropdownChange}
                                        required
                                        blurInputOnSelect
                                />
                            </div>
                            {data.category !== '' &&
                                <>
                                    <div className="form__cell form__cell--2col d-none d-lg-block"></div>
                                    <div className="form__cell form__cell--2col">
                                        <Form.Label htmlFor="num">No.</Form.Label>
                                        <input type="text"
                                               id="num"
                                               name="num"
                                               value={data.num}
                                               onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form__cell form__cell--2col">
                                        {data.category.value === 'invoices' ?
                                            <>
                                                <Form.Label htmlFor="type">Type</Form.Label>
                                                <CreatableSelect id="type"
                                                                 name="type"
                                                                 className="react-select-container"
                                                                 classNamePrefix="react-select"
                                                                 defaultValue={data.type}
                                                                 options={terms}
                                                                 onChange={handleDropdownChange}
                                                                 placeholder={'Choose or create new type'}
                                                />
                                            </> : <>
                                                <Form.Label htmlFor="name">Name *</Form.Label>
                                                <input type="text"
                                                       id="name"
                                                       name="name"
                                                       value={data.name}
                                                       onChange={handleChange}
                                                       required
                                                />
                                            </>
                                        }
                                    </div>
                                    <div className="form__cell form__cell--2col">
                                        <Form.Label htmlFor="currency">Currency *</Form.Label>
                                        <Select id="currency"
                                                name="currency"
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                defaultValue={data.currency}
                                                options={currency}
                                                onChange={handleDropdownChange}
                                                blurInputOnSelect
                                                required
                                        />
                                    </div>
                                    <div className="form__cell form__cell--2col">
                                        <Form.Label htmlFor="amount">Amount *</Form.Label>
                                        <input type="number"
                                               id="amount"
                                               name="amount"
                                               value={data.amount}
                                               onChange={handleChange}
                                               required
                                        />
                                    </div>
                                    <div className="form__cell form__cell--2col">
                                        <Form.Label htmlFor="dueDate">
                                            {data.category.value === 'invoices' ? 'Due date' : 'Date'} *
                                        </Form.Label>
                                        <div>
                                            <DatePicker id="dueDate"
                                                        name="dueDate"
                                                        selected={data.dueDate}
                                                        dateFormat="dd MMM yyyy"
                                                        wrapperClassName="d-block"
                                                        onChange={(date) => setData({...data, dueDate: date})}
                                            />
                                        </div>
                                    </div>
                                    {data.category.value === 'invoices' ?
                                        <div className="form__cell form__cell--2col mb-30">
                                            <Form.Check
                                                type="checkbox"
                                                label="Paid"
                                                name="isPaid"
                                                id="isPaid"
                                                checked={data.isPaid}
                                                onChange={(e) => setData({...data, [e.target.name]: e.target.checked})}
                                            />
                                        </div>
                                        :
                                        <div className="form__cell form__cell--2col">
                                            <Form.Label htmlFor="status">Status *</Form.Label>
                                            <Select
                                                id="status"
                                                name="status"
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                value={data.status}
                                                options={status}
                                                onChange={handleDropdownChange}
                                                required
                                            />
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    ) : (
                        <>
                            <Form.Label htmlFor="tags">Tags</Form.Label>
                            <CreatableSelect id="tags"
                                             name="tags"
                                             className="react-select-container"
                                             classNamePrefix="react-select"
                                             value={tags}
                                             options={suggestions}
                                             onChange={handleTagChange}
                                             placeholder={'Choose or create new tags'}
                                             isMulti
                            />
                            <Form.Label htmlFor="remarks">Remarks</Form.Label>
                            <input type="text"
                                   id="remarks"
                                   name="remarks"
                                   value={data.remarks}
                                   onChange={handleChange}
                            />
                        </>
                    )}
                    <button type="submit" className="btn">Submit</button>
                </form>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        uploadProgress: state.global.file_upload_progress,
        project: state.global.project,
    };
};

export default connect(mapStateToProps, {
    createTag,
    createTerm,
    fetchUser,
    updateFile,
    uploadFile,
    uploadFileInfo,
})(FileManager);