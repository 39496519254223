import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

import Modal from 'react-bootstrap/Modal';

function ModalWrapper(props) {
    const navigate = useNavigate();
    const {content, background} = props;
    const [show, setShow] = useState(true)

    const handleClose = () => {
        setShow(false);
        navigate(background.pathname);
    }

    return (
        background ?
            <Modal show={show}
                   size="xl"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
            >
                <div className="modal__close" onClick={() => handleClose()}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="0.353553" y1="0.646447" x2="22.981" y2="23.2739" stroke="#16161D"/>
                        <line x1="0.646447" y1="23.2739" x2="23.2739" y2="0.646471" stroke="#16161D"/>
                    </svg>
                </div>
                <Modal.Body>{content}</Modal.Body>
            </Modal>
            :
            <>{content}</>
    );
}

export default ModalWrapper;