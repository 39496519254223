import {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";

import axiosAuth from "../axios";

function Navigation(props) {
    const {user, project} = props;
    const [newFile, setNewFile] = useState({
        project: false,
        financial: false,
    });

    useEffect(() => {
        const scanNewFiles = async () => {
            for (const file of project.files) {
                if (!user.downloadHistory.includes(file)) {
                    const {data: fileInfo} = await axiosAuth.post('/file/fetchOne', {
                        fileId: file
                    });
                    if (fileInfo) {
                        switch (fileInfo.tab) {
                            case 'project':
                                setNewFile(f => ({...f, project: true}))
                                break;
                            case 'financial':
                                setNewFile(f => ({...f, financial: true}))
                                break;
                            default:
                                break;
                        }
                    }
                }
            }
        }

        if (project.files) scanNewFiles().then();
    }, [user.downloadHistory, project.files, props]);

    return (
        <div id="nav_container" className="w-100 overflow-auto">
            <div className="max-content">
                <nav className="nav" data-tut="project__navigation">
                    <ul className="nav__list" data-tut="project__dashboard">
                        <li className="nav__item">
                            <NavLink className="nav__link"
                                     to={`/project/${project._id}/dashboard`}
                            >Dashboard</NavLink>
                        </li>
                    </ul>
                    <ul className="nav__list"
                        data-tut="project__files"
                        style={{marginLeft: '54px'}}
                    >
                        <li className={`nav__item`}>
                            <NavLink className={`nav__link${newFile.project ? ' nav__link--dotted' : ''}`}
                                     to={`/project/${project._id}/project-files`}
                            >Project files</NavLink>
                        </li>
                        <li className={`nav__item`}>
                            <NavLink className={`nav__link${newFile.financial ? ' nav__link--dotted' : ''}`}
                                     to={`/project/${project._id}/financial-files`}
                            >Financial files</NavLink>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        file_downloaded: state.global.file_downloaded,
        file_uploaded: state.global.file_uploaded,
        file_deleted: state.global.file_deleted,
    };
};

export default connect(mapStateToProps, {})(Navigation);