import {toTitleCase} from '../utils/Helpers';

function FilterBar(props) {
    const {category, descendingOrder, updateSorting, updateFactor, initFactor, factorList} = props;
    const filters = ['invoices', 'quotes'];

    return (
        <div className="filter-bar">
            <div className="filter-bar__right">
                <div className={`filter-bar__item filter-bar__sorting ${descendingOrder ? 'desc' : 'asc'}`}>
                    <div className="filter-bar__sorting-btn"
                         onClick={updateSorting}
                    >
                        Sort
                        <span className="mx-1">{descendingOrder ? 'desc' : 'asc'}ending</span>
                        by
                    </div>
                    &nbsp;
                    <select onChange={updateFactor} value={initFactor}>
                        {factorList.map((factor) =>
                            <option key={factor.value}
                                    value={factor.value}
                            >{factor.label}</option>
                        )}
                    </select>
                </div>
            </div>
            <div className="filter-bar__left">
                {category !== undefined ?
                    filters.map((filter, i) =>
                        <div className={`filter-bar__item${category === filter ? ' active' : ''}`}
                             style={{width: '200px'}} onClick={() => props.updateCategory(filter)}
                             key={i}
                        >{toTitleCase(filter)}</div>
                    )
                    :
                    null
                }
            </div>
        </div>
    )
}

export default FilterBar;