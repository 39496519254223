//React Imports
import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";

//React Redux Imports
import {connect} from "react-redux";
import {getProjects} from "../../store/actions";

//Axios Imports
import axiosAuth from '../../axios';
import {toast} from 'react-toast';

function ProjectNew(props) {
    const user = JSON.parse(props.user);
    const [project, setProject] = useState({
        title: "",
        company: "",
        objective: "",
        jobnum: "",
        createdBy: user._id,
        members: [{"_id": user._id, type: "key"}],
    });

    const navigate = useNavigate();

    const handleChange = ({currentTarget: input}) => {
        setProject({...project, [input.name]: input.value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const url = process.env.REACT_APP_API + "/project/new";
        await axiosAuth.post(url, {
            project,
        }).then((response) => {
            if (response.status === 201) {
                navigate("/projects");
                toast.success(response.data.message);
            }
        }).catch((error) => {
            if (error.response && error.response.status >= 400 && error.response.status <= 500) toast.error(error.message);
        })
        props.getProjects(user);
    }

    return (
        <>
            <div className={props.background && 'form__container'}>
                <h1>Create new project</h1>
                <div className="form__remarks">* Mandatory fields</div>
                <form action="" onSubmit={handleSubmit}>
                    <label htmlFor="title">Project title *</label>
                    <input id="title"
                           name="title"
                           value={project.title}
                           type="text"
                           onChange={handleChange}
                           required
                    />
                    <div className="form__cell form__cell--2col">
                        <label htmlFor="company">Company name *</label>
                        <input id="company"
                               name="company"
                               value={project.company}
                               type="text"
                               onChange={handleChange}
                               required
                        />
                    </div>
                    <div className="form__cell form__cell--2col">
                        <label htmlFor="jobnum">Job number</label>
                        <input id="jobnum"
                               name="jobnum"
                               value={project.jobnum}
                               type="text"
                               onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="objective">Objective</label>
                        <textarea id="objective"
                                  name="objective"
                                  value={project.objective}
                                  onChange={handleChange}
                                  maxLength="1000"
                        />
                    </div>
                    <button type="submit" className="btn">Submit</button>
                </form>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {
        projectList: state.project.projectList,
    };
};

export default connect(mapStateToProps, {
    getProjects
})(ProjectNew);