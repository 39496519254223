//React Imports
import {useState, useRef} from "react";
import {Navigate, useNavigate} from "react-router-dom";

//Redux Imports
import {connect} from "react-redux";
import {signIn} from "../store/actions";

//Axios Imports
import axios from "axios";

//Bootstrap Imports
import Form from "react-bootstrap/Form";

import ReCAPTCHA from "react-google-recaptcha";

function Login(props) {
    const captchaRef = useRef(null);

    const [data, setData] = useState({
        email: "",
        password: "",
    });
    const [rememberMe, setRememberMe] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const handleChange = ({currentTarget: input}) => setData({...data, [input.name]: input.value});

    const [error, setError] = useState("");
    const navigate = useNavigate();
    const devMode = process.env.APP_MODE === 'development' ||
        process.env.REACT_APP_SITE_URL.includes('localhost') ||
        process.env.REACT_APP_SITE_URL.includes('127.0.0.1');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const url = process.env.REACT_APP_API + "/auth";
            const {data: res} = await axios.post(url, {
                ...data,
                rememberMe: rememberMe,
                ...(!devMode && {rcToken: captchaRef.current.getValue()}),
            });
            props.signIn(res.data);
            navigate("/projects")
        } catch (error) {
            if (
                error.response &&
                error.response.status >= 400 &&
                error.response.status <= 500
            ) setError(error.response.data.message);
        }
        if (!devMode) captchaRef.current.reset();
    };

    return (
        <>
            {
                !props.token ?
                    <div className="login">
                        <div className="login__left">
                            <img src="/assets/images/img-login.svg"
                                 alt="" width="382" height="603"
                            />
                        </div>
                        <div className="login__right">
                            <img src="/assets/images/logo-stepworks.svg"
                                 alt="" width="166" height="30"
                                 className="login__logo"
                            />

                            <h1 className="h2 login__title">Login to view your project dashboard</h1>

                            <Form action="" onSubmit={handleSubmit}>
                                <Form.Control type="text"
                                              name="email"
                                              id="email"
                                              value={data.email}
                                              className="login__input"
                                              placeholder="Email"
                                              onChange={handleChange}
                                              required
                                />
                                <div className="login__input">
                                    <Form.Control type={showPassword ? 'text' : 'password'}
                                                  name="password"
                                                  id="password"
                                                  value={data.password}
                                                  placeholder="Password"
                                                  onChange={handleChange}
                                                  required
                                    />
                                    <i className="material-symbols-outlined fw-bold icon--small"
                                       onClick={() => setShowPassword(!showPassword)}
                                    >{showPassword ? 'visibility_off' : 'visibility'}</i>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <p className="login__row">
                                        <label htmlFor="remember-me">
                                            <input type="checkbox"
                                                   name="remember-me"
                                                   id="remember-me"
                                                   onChange={() => setRememberMe(!rememberMe)}
                                                   checked={rememberMe}
                                            />
                                            Remember me
                                        </label>
                                    </p>
                                    <p className="login__row"><a href="/forgot-password">Forgot password</a></p>
                                </div>
                                {
                                    !devMode && <ReCAPTCHA
                                        ref={captchaRef}
                                        sitekey={process.env.REACT_APP_SITE_KEY}
                                    />
                                }
                                <br/>
                                <button type="submit" className="btn login__btn">Login</button>
                                <p className="login__row login__forgetpwd">
                                    Don’t have an account?
                                    <a className="login__link" href="mailto:hello@stepworks.co">Contact us</a>
                                </p>
                            </Form>
                            {error && <div className="login__error">{error}</div>}
                        </div>
                    </div>
                    :
                    <Navigate to="/projects"/>
            }
        </>
    );
}

const mapStateToProps = state => {
    return {};
};

export default connect(mapStateToProps, {signIn})(Login);