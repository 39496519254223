//React Imports
import React, {useEffect} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Navigate} from "react-router-dom";

//React Redux Imports
import {connect} from "react-redux";
import {fetchAllUsers} from "../../store/actions";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import DotDotDot from "../../components/DotDotDot";

function UserList(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const user = JSON.parse(props.user);
    const {fetchAllUsers} = props;

    // Get all users
    useEffect(() => {
        const fetchAllUsersFn = async () => {
            await fetchAllUsers();
        }

        fetchAllUsersFn().then();
    }, [fetchAllUsers, props.users]);

    useEffect(() => {
        if (user.role !== 'admin') navigate('/projects');
    }, [navigate, user]);

    if (!user) return <Navigate to="/login" replace/>;

    return (
        <>
            <h1 className="main__title">User List</h1>
            <div className="right mb-0 mt-4 mb-md-4 mt-md-0">
                {user.role === "admin" &&
                    <Link className="h2 btn--action gap-2" to="/user/new" state={{background: location}}>
                        <i className="material-symbols-outlined text--red icon icon--large">add_circle</i>
                        <span>Add new user</span>
                    </Link>
                }
            </div>
            {
                props?.users ?
                    <table className="datatable">
                        <colgroup>
                            <col width="45%"/>
                            <col width="30%"/>
                            <col width="20%"/>
                            <col width="5%"/>
                        </colgroup>
                        <tbody>
                        <tr className="datatable__row">
                            <th className="datatable__header">Name</th>
                            <th className="datatable__header d-none d-md-table-cell">Company</th>
                            <th className="datatable__header d-none d-md-table-cell">Role</th>
                            <th className="datatable__header"></th>
                        </tr>
                        {props?.users && props.users.map((user, i) =>
                            <tr key={i} className="datatable__row">
                                <td className="datatable__cell">{user.firstName} {user.lastName}</td>
                                <td className="datatable__cell d-none d-md-table-cell">{user.company}</td>
                                <td className="datatable__cell d-none d-md-table-cell text-capitalize">{user.role}</td>
                                <td className="datatable__cell">
                                    <DotDotDot>
                                        <Link to={`/user-information/${user._id}`}
                                              state={{background: location}}
                                              className="menu__link"
                                        >
                                            <div className="dotdotdot__option edit" data-rowidx={i}>Edit</div>
                                        </Link>
                                    </DotDotDot>
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table> :
                    <Container fluid>
                        <br/><br/><br/><br/>
                        <Row>
                            <Col md={{span: 6, offset: 6}}>
                                <Spinner animation="border" variant="danger"/>
                            </Col>
                        </Row>
                    </Container>
            }
        </>
    );
}

const mapStateToProps = state => {
    return {
        users: state.global.users,
    };
};

export default connect(mapStateToProps, {
    fetchAllUsers
})(UserList);
  