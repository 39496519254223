import axiosAuth from "./";
import { useNavigate } from "react-router-dom";

const SetupInterceptors = () => {
    let navigate = useNavigate();
    axiosAuth.interceptors.response.use((response) => {
        return response;
    }, function (error) {
        var status = error.response.status;
        var resBaseURL = error.response.config.baseURL;
        if (resBaseURL === process.env.REACT_APP_API && (status === 403 || status === 401)) {
          localStorage.removeItem("ph-user");
          localStorage.removeItem("ph-token");
          localStorage.removeItem("ph-current-project");
          sessionStorage.removeItem('appReloaded');
          navigate("/login");
        }
        return Promise.reject(error);
    });
}

export default SetupInterceptors;