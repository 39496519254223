import Moment from "react-moment"

function Footer() {
    return (
        <footer>
            <div className="footer container">
                <div className="d-block d-md-flex justify-content-between">
                    <div className="footer__left">
                        <p className="footer__app-name">
                            <a href="https://stepworks.co/"
                               target="_blank"
                               rel="noreferrer"
                            >
                                <img src="/assets/images/logo-stepworks.svg"
                                     alt="Stepworks" width="120" height="30"/>
                            </a>
                            Project Hub
                            <sup>BETA</sup>
                        </p>
                    </div>
                    <div className="footer__right">
                        <Moment interval={1000} format="ddd DD MMMM YYYY, H:mm:ss"/>
                    </div>
                </div>
                <small>Ver <b>1.0.0</b></small>
            </div>
        </footer>
    )
}

export default Footer;