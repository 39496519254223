export const ASSIGN_PROJECT = "ASSIGN_PROJECT";
export const GET_PROJECTS = "GET_PROJECTS";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const CREATE_SCOPE = "CREATE_SCOPE";
export const UPDATE_SCOPE = "UPDATE_SCOPE";
export const DELETE_SCOPE = "DELETE_SCOPE";
export const GET_PROJECT_MEMBERS = "GET_PROJECT_MEMBERS";
export const MANAGE_PROJECT_MEMBERS = "MANAGE_PROJECT_MEMBERS";
export const UPDATE_PROJECT_MEMBER = "UPDATE_PROJECT_MEMBER";
export const CREATE_TAG = "CREATE_TAG";