import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./assets/css/main.min.css";
import 'material-symbols/outlined.css';

/* 
*
REDUX
*
*/
import store from "./store";
import { Provider } from "react-redux";

/* 
*
COMPONENTS
*
*/
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
