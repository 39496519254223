function TextInput(props) {
    const {content, editable, textarea, refNode, className} = props;
    return (
        textarea ?
            <textarea name={content.name}
                      defaultValue={content.value}
                      readOnly={!editable}
                      onChange={props.handleChange}
                      ref={refNode}
            /> :
            <input type="text"
                   name={content.name}
                   defaultValue={content.value}
                   readOnly={!editable}
                   onChange={props.handleChange}
                   className={className}
            />
    );
}

export default TextInput;