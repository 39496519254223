import {useEffect, useState} from "react";
import Tag from "./Tag";

function SearchFilter(props) {
    const [value, setValue] = useState(props.value);
    const resetFilter = () => {
        setValue('');
        props.handleClick();
    }
    const handleChange = (e) => {
        setValue(e.target.value);
        props.handleChange(e);
    }
    useEffect(() => {
        setValue(props.value);
    }, [props])
    return (
        <>
            <div className="search-filter mt-5">
                <input id="query"
                       name="query"
                       type="text"
                       className="search-filter__input"
                       onChange={handleChange}
                       value={value}
                       placeholder={props.placeholder}
                />
                <i className={`search-filter__clear material-symbols-outlined icon icon--small${props.value.length === 0 ? ' hidden' : ''}`}
                   onClick={resetFilter}
                >close</i>
            </div>
            {props.tags &&
                <div className="overflow-auto">
                    <div className="search-filter__container">
                        <div className="search-filter__tags">
                            {
                                props.tags.map((tag) =>
                                    <Tag key={`page-tag-${tag}`} id={tag}
                                         handleClick={() => props.handleTagOnClick(tag)}>{tag}</Tag>
                                )
                            }
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default SearchFilter;