import { ASSIGN_PROJECT } from "../actions/types/project";
import { FETCH_ALL_USERS } from "../actions/types/user";
import { UPLOAD_FILE, UPLOAD_FILE_INFO, RESPOND_FILE_PROGRESS, UPDATE_FILE, DOWNLOAD_FILE, DELETE_FILE } from "../actions/types/file";
import { CREATE_TERM } from "../actions/types/term";

const INITIAL_STATE = {
  users: [],
  project: {
    members:[]
  },
  file_uploaded: '',
  file_upload_progress: 0,
  file_updated: '',
  file_downloaded: '',
  file_deleted: '',
  term_created: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {      
    case ASSIGN_PROJECT: 
      return {
        ...state,
        project: action.payload
      };
    case UPLOAD_FILE:
      return {
        ...state,
        file_upload_progress: action.payload.resetUploadProgress
      };
    case UPLOAD_FILE_INFO:
      return {
        ...state,
        file_uploaded: action.payload,
      };
    case RESPOND_FILE_PROGRESS:
      return {
        ...state,
        file_upload_progress: action.payload,
      }
    case UPDATE_FILE:
      return {
        ...state,
        file_updated: action.payload
      };
    case FETCH_ALL_USERS:
      return {
        ...state,
        users: action.payload
      };
    case DOWNLOAD_FILE: 
      return {
        ...state,
        file_downloaded: action.payload,
      }
    case DELETE_FILE:
      return {
        ...state,
        file_deleted: action.payload,
      }        
    case CREATE_TERM:
      return {
        ...state,
        term_created: action.payload,
      }        
    default:
      return state;
  }
};
