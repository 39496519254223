//React Imports
import React, {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {Navigate} from "react-router-dom";
import Tour from "reactour";
import DotDotDot from "../../components/DotDotDot";

//React Redux Imports
import {connect} from "react-redux";
import {assignProject, getProjects, updateProjectStatus, updateTour} from "../../store/actions";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {toast} from "react-toast";

function ProjectList(props) {
    const location = useLocation();
    const user = JSON.parse(props.user);

    const {updateTour} = props;
    const [update, setUpdate] = useState(true);
    const [isTourOpen, setIsTourOpen] = useState(!user?.tour?.includes('projectList'));
    const [current, setCurrent] = useState(0);
    const [noTour, setNoTour] = useState(false);

    const steps = [
        {
            content: () => (
                <>
                    Do you want to have a tour?
                    <br/><br/>
                    <div className="d-flex justify-content-between">
                        <button
                            className="border-0 bg-transparent text--red text-decoration-underline"
                            onClick={() => setCurrent(current + 1)}
                        >Yes
                        </button>
                        <button
                            className="border-0 bg-transparent text-decoration-underline"
                            onClick={() => {
                                setIsTourOpen(false);
                                setNoTour(true);
                                document.getElementsByTagName('body')[0].style = '';
                            }}
                        >No, thanks
                        </button>
                    </div>
                </>
            ),
            action: () => {
                document.getElementsByTagName('body')[0].style = 'overflow: hidden';
            }
        },
        {
            selector: '[data-tut="project_table"]',
            content: () => (
                <>
                    Here's your projects
                    <br/><br/>
                    <div className="text-end">
                        <button
                            className="border-0 bg-transparent text--red text-decoration-underline"
                            onClick={() => setCurrent(current + 1)}
                        >Got it
                        </button>
                    </div>
                </>
            ),
            action: () => {
                document.getElementById('root').style = 'pointer-events: none';
            }
        },
        {
            selector: '[data-tut="avatar"]',
            content: 'Click the avatar to open the menu',
            action: () => {
                document.getElementById('avatar_menu').classList.remove('open');
                document.getElementById('root').style = '';

                document.body.addEventListener('click', function (event) {
                    if (document.getElementById("avatar").contains(event.target)) {
                        setTimeout(() => {
                            setCurrent(current + 1);
                        }, 250);
                    }
                });
            }
        },
        {
            selector: '[data-tut="avatar_menu"]',
            content: 'Click "Account settings" to view your personal information',
            action: () => {
                document.getElementById('avatar_menu').classList.add('open');
                document.getElementById('avatar_menu').style = 'opacity: 1; transform: translateY(0); pointer-events: auto; display: block; width: max-content;';

                document.body.addEventListener('click', function (event) {
                    if (document.getElementById("personal_info").contains(event.target)) {
                        setTimeout(() => {
                            setCurrent(current + 1);
                        }, 200);
                    }
                });
            },
        },
        {
            selector: '[data-tut="account_setting"]',
            content: () => (
                <>
                    You can change the colour of your avatar, password, and request an edit for your information
                    <br/><br/>
                    <div className="text-end">
                        <button
                            className="border-0 bg-transparent text--red text-decoration-underline"
                            onClick={() => {
                                setCurrent(current + 1);
                                document.getElementsByClassName('modal-content')[0].style = '';
                                document.getElementsByClassName('modal__close')[0].click();
                            }}
                        >Got it
                        </button>
                    </div>
                </>
            ),
            action: () => {
                document.getElementsByClassName('modal-content')[0].style = 'pointer-events: none';
                document.getElementById('avatar_menu').classList.remove('open');
                document.getElementById('avatar_menu').style = '';
            },
        },
        {
            selector: '[data-tut="homepage_logo"]',
            content: () => (
                <>
                    Finally you can go back to the project list anytime by clicking on the Stepworks logo here
                    <br/><br/>
                    <div className="text-end">
                        <button
                            className="border-0 bg-transparent text--red text-decoration-underline"
                            onClick={() => {
                                setIsTourOpen(false);
                                document.getElementsByTagName('body')[0].style = '';
                                document.getElementById('root').style = '';
                            }}
                        >OK!
                        </button>
                    </div>
                </>
            ),
            action: () => {
                document.getElementById('root').style = 'pointer-events: none';
            },
        },
    ]

    useEffect(() => {
        const getProjects = async (user) => {
            await props.getProjects(user);
            setUpdate(false);
        };

        if (update) getProjects(user).then();
    }, [update, props, user]);

    useEffect(() => {
        const updateUserTour = async () => {
            let tour = user['tour'] ?? [];
            tour.push('projectList');
            if (noTour) tour.push('projectDashboard');
            user['tour'] = tour.filter((value, index, array) => array.indexOf(value) === index);

            await updateTour(user);
        }
        if (!user?.tour?.includes('projectList') && !isTourOpen) updateUserTour().then();
    }, [isTourOpen, user, updateTour, noTour]);

    if (!user) return <Navigate to="/login" replace/>;

    const projectOnClick = (project) => {
        localStorage.setItem("ph-current-project", JSON.stringify(project));
        props.assignProject(project);
    }

    const updateStatus = async (projectId, status) => {
        await props.updateProjectStatus(projectId, status)
            .then((res) => {
                toast.success(`Project status has been updated successfully.`);
                setUpdate(true);
            });
    }

    return (
        <>
            <Tour steps={steps}
                  isOpen={isTourOpen}
                  accentColor="#eb0c38"
                  rounded={5}
                  onRequestClose={() => setIsTourOpen(false)}
                  stepInteraction={false}
                  disableKeyboardNavigation={true}
                  disableDotsNavigation={true}
                  showButtons={false}
                  goToStep={current}
                  prevStep={() => setCurrent(current - 1)}
                  nextStep={() => setCurrent(current + 1)}
                  showNavigationNumber={false}
                  showCloseButton={false}
                  closeWithMask={false}
            />

            <h1 className="main__title">Hi {user.firstName}!</h1>

            <div className="right mb-0 mt-4 mb-md-4 mt-md-0">
                {user.role === "admin" &&
                    <Link className="h2 btn--action gap-2"
                          to="/project/new"
                          state={{background: location}}
                    >
                        <i className="material-symbols-outlined text--red icon icon--large">add_circle</i>
                        <span>Add new project</span>
                    </Link>
                }
            </div>
            {props?.projectList ?
                <table className="datatable" data-tut="project_table">
                    <colgroup>
                        <col width="65%"/>
                        <col width="30%"/>
                        <col width="5%"/>
                    </colgroup>
                    <tbody>
                    <tr className="datatable__row">
                        <th className="datatable__header">Project name</th>
                        <th className="datatable__header d-none d-md-table-cell">Job No.</th>
                        <th className="datatable__header"></th>
                    </tr>
                    {props?.projectList.length > 0 && props.projectList.map((project, i) =>
                        <tr key={i} className="datatable__row">
                            <td className="datatable__cell">
                                <Link className="datatable__link"
                                      to={`/project/${project._id}/dashboard`}
                                      onClick={() => projectOnClick(project)}
                                >{project.title}</Link>
                            </td>
                            <td className="datatable__cell d-none d-md-table-cell">{project.jobnum}</td>
                            <td className="datatable__cell align-top">
                                {user.role === "admin" &&
                                    <DotDotDot>
                                        <div className="dotdotdot__option update"
                                             data-rowidx={i}
                                             onClick={() => updateStatus(project._id, !project.status)}
                                        >{project.status === 1 ? 'Deactivate' : 'Activate'} project</div>
                                    </DotDotDot>
                                }
                            </td>
                        </tr>
                    )}
                    {props?.projectList.length === 0 &&
                        <tr>
                            <td colSpan={4} className="text-center p-3">No active project recently</td>
                        </tr>
                    }
                    </tbody>
                </table>
                :
                <Container fluid>
                    <br/><br/><br/><br/>
                    <Row>
                        <Col md={{span: 6, offset: 6}}>
                            <Spinner animation="border" variant="danger"/>
                        </Col>
                    </Row>
                </Container>
            }
        </>
    );
}

const mapStateToProps = state => {
    return {
        projectList: state.project.projectList,
    };
};

export default connect(mapStateToProps, {
    getProjects,
    assignProject,
    updateProjectStatus,
    updateTour
})(ProjectList);
  