import { confirmDialog } from "../../utils/Helpers";
import { toast } from "react-toast";

//Axios Imports
import axiosAuth from "../../axios";

//Redux Types Imports
import { default as types } from './types';

// USER RELATED

export const signIn = (data) => {
  localStorage.setItem("ph-token", data.token);
  localStorage.setItem("ph-user", JSON.stringify(data.user));
  return {
    type: types.SIGN_IN,
    payload: data,
  };
};

export const signOut = () => (dispatch) => {
  localStorage.removeItem("ph-user");
  localStorage.removeItem("ph-token");
  localStorage.removeItem("ph-current-project");
  sessionStorage.removeItem('appReloaded');
  dispatch({
    type: types.SIGN_OUT,
  });
};

export const getUser = () => (dispatch) => {
  let user = localStorage.getItem("ph-user");
  if(user) {
    user = JSON.parse(user);
  }
  dispatch({
    type: types.GET_USER,
    payload: user
  });
};

export const fetchUser = (userId, updateStore = true) => async (dispatch) => {
  const { data } = await axiosAuth.post('/user/fetchOne', {
    userId
  });
  if (updateStore) {
    dispatch({
      type: types.RELOAD_USER,
      payload: data
    });
  }  
  return data;
};

export const fetchAllUsers = () => async (dispatch) => {
  const { data } = await axiosAuth.get('/user/fetchAll');
  dispatch({
    type: types.FETCH_ALL_USERS,
    payload: data
  });
};

export const getUserToken = () => async (dispatch) => {
  let token = localStorage.getItem("ph-token");
  // TODO: check if the token expires
  const { data } = await axiosAuth.get('/auth/verify', {
    params: {
      token
    }    
  });
  if (!data.result) {
    return false;
  } 
  dispatch({
    type: types.GET_USER_TOKEN,
    payload: token
  });
  return token;
};

export const updateUser = (userDetails, currentUser = true) => async (dispatch) => {
  if (currentUser) {
    localStorage.setItem("ph-user", JSON.stringify(userDetails));
  }  
  const response = await axiosAuth.post('/user/update', {
    userDetails
  });
  dispatch({
    type: types.UPDATE_USER,
    payload: userDetails
  });
  return response;
};

export const updateTour = (userDetails) => async () => {
  localStorage.setItem("ph-user", JSON.stringify(userDetails));
  return await axiosAuth.post('/user/updateTour', {
    userDetails
  });
};

export const updatePassword = (pwdDetails, user) => async () => {
  return await axiosAuth.post('/auth/changePassword', {
    userId: user._id,
    oldPassword: pwdDetails.currentPassword,
    password: pwdDetails.newPassword,
  });
};

// end

export const requestEdit = (user, edit) => async (dispatch) => {
  localStorage.setItem("ph-user", JSON.stringify(user));
  const response = await axiosAuth.post('/user/requestedit', {
      edit,
      user
  });
  dispatch({
    type: types.GET_USER,
    payload: user
  });
  return response;
};

export const registerNewUser = (formData) => async (dispatch) => {
  const res = await axiosAuth.post("/register/new", { 
    formData,
  });
  dispatch({
    type: types.REGISTER_NEW_USER,
    payload: formData
  });
  return res;
};

// PROJECT RELATED

export const assignProject = (project) => async (dispatch) => {
  dispatch({
    type: types.ASSIGN_PROJECT,
    payload: project,
  });
}

export const getProjects = (user) => async (dispatch) => {
  const token = localStorage.getItem('ph-token');
  const { data } = await axiosAuth.post('/project/fetch', {
    userId: user._id
  }, 
  {
    headers: {
      Authorization: `Bearer ${token}`
    },
  });
  dispatch({
    type: types.GET_PROJECTS,
    payload: data,
  });
  return data;
};

export const getArchivedProjects = (user) => async (dispatch) => {
  const token = localStorage.getItem('ph-token');
  const { data } = await axiosAuth.post('/project/fetchArchived', {
        userId: user._id
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
  dispatch({
    type: types.GET_PROJECTS,
    payload: data,
  });
  return data;
};

export const updateProject = (userId, projectId, projectData) => async (dispatch) => {
  const { data } = await axiosAuth.post('/project/update', {
    userId,
    projectId,
    projectData,
  });
  dispatch({
    type: types.UPDATE_PROJECT,
    payload: data,
  });
  return data;
}

export const updateProjectStatus = (projectId, status) => async (dispatch) => {
  const { data } = await axiosAuth.post('/project/updateStatus', {
    projectId,
    status,
  });
  dispatch({
    type: types.UPDATE_PROJECT,
    payload: data,
  });
  return data;
}

export const deleteProject = (user, project) => async (dispatch) => {
  const { _id: projectId, title: projectName } = project;
  confirmDialog(`Are you confirm to delete ${projectName}?`)
  .then(async () => {
    const { data } = await axiosAuth.post('/project/delete', {
      userId: user._id,
      projectId
    });
    dispatch({
      type: types.DELETE_PROJECT,
      payload: data
    })
    toast.success(`${projectName} has been deleted successfully`);
  })
  .catch(err => {})
}

export const managingProjectTeam = (userArray, projectId = null) => async (dispatch) => {
  const res = await axiosAuth.post("/project/updateTeamMembers", { 
    userArray,
    projectId
  });
  dispatch({
    type: types.MANAGE_PROJECT_MEMBERS,
    payload: res.data.members
  });
  return res;
};

export const getMembers = (projectId, members) => async (dispatch) => {
  const { data } = await axiosAuth.post('/project/fetchProjectTeam', {
    projectId,
    members 
  });
  dispatch({
    type: types.GET_PROJECT_MEMBERS,
    payload: data,
  });
  return data;
};

export const createScope = (projectId, scopeData) => async (dispatch) => {  
  const { data } = await axiosAuth.post('/project/createScope', {
    projectId,
    scopeData,
  });
  dispatch({
    type: types.CREATE_SCOPE,
    payload: data,
  });
  return data;
}

export const updateScope = (projectId, scopeId, scopeData) => async (dispatch) => {
  const { data } = await axiosAuth.post('/project/updateScope', {
    projectId,
    scopeId,
    scopeData,
  });
  dispatch({
    type: types.UPDATE_SCOPE,
    payload: data,
  });
  return data;
}

export const deleteScope = (projectId, scope) => async (dispatch) => {
  const { task: taskName, _id: scopeId } = scope;
  confirmDialog(`Are you confirm to delete ${taskName}?`)
  .then(async () => {
    const { data } = await axiosAuth.post('/project/deleteScope', {
      projectId,
      scopeId,
    });
    dispatch({
      type: types.DELETE_SCOPE,
      payload: data,
    });
    toast.success(`${taskName} has been deleted successfully`);
  })
  .catch(err => {})
}

export const createTag = (projectId, tag) => async (dispatch) => {
  const { data } = await axiosAuth.post('/project/createTag', {
    projectId,
    tag
  });
  dispatch({
    type: types.CREATE_TAG,
    payload: data,
  });
  return data;
}

export const createTerm = (term, type) => async (dispatch) => {
  const { data } = await axiosAuth.post('/term/new', {
    term,
    type,
  });
  dispatch({
    type: types.CREATE_TERM,
    payload: data,
  });
  return data;
}

// FILE RELATED
export const uploadFile = (formData, current, total) => async (dispatch) => {
  const percentCompleted = current === 1 && total !== 1 ? 0 : Math.round(
      (current / total) * 100
  );
  const response = await axiosAuth.post("/file/uploadFile", formData,
      {
        onUploadProgress: () => {
          dispatch({
            type: types.RESPOND_FILE_PROGRESS,
            payload: percentCompleted,
          })
        }
      });
  dispatch ({
    type: types.UPLOAD_FILE,
    payload: {
      file: response.data.file,
      resetUploadProgress: percentCompleted,
    },
  });
  return response;
}

export const uploadFileInfo = (formData) => async (dispatch) => {
  const response = await axiosAuth.post("/file/uploadFileInfo", formData);
  dispatch ({
    type: types.UPLOAD_FILE_INFO,
    payload: response.data,
  });
  return response;
}

export const updateFile = (fileData) => async (dispatch) => {
  const response = await axiosAuth.post("/file/update", {
    ...fileData
  });
  dispatch ({
    type: types.UPDATE_FILE,
    payload: response.data,
  });
  return response;
}

export const downloadFile = (userId, fileId, projectId) => async (dispatch) => {
  const response = await axiosAuth.post('/file/track', {
    userId,
    fileId,
    projectId,
  });
  dispatch({
    type: types.DOWNLOAD_FILE,
    payload: response.data.file,
  });
  localStorage.setItem('ph-user', JSON.stringify(response.data.user));
  dispatch({
    type: types.GET_USER,
    payload: response.data.user,
  });
  return response;
}

export const deleteFile = (project, file) => async (dispatch) => {
  const { _id: projectId } = project;
  const { _id: fileId, filename } = file;
  confirmDialog(`Are you confirm to delete ${filename}?`)
  .then(async () => {
    const { data } = await axiosAuth.post('/file/delete', {
      fileId,
      projectId
    });
    dispatch({
      type: types.DELETE_FILE,
      payload: data
    })
    toast.success(`${filename} has been deleted successfully`);
  })
  .catch(err => {})
}