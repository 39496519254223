import axios from "axios";

const token = localStorage.getItem("ph-token");

const axiosAuth = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
        Authorization: `Bearer ${token}`
    }
})

export default axiosAuth;