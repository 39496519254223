//React Imports
import {useState} from "react";
import {Navigate, useNavigate} from "react-router-dom";

//Axios Imports
import {toast} from 'react-toast';
import axios from "axios";

function ForgotPassword({token}) {
    const [data, setData] = useState({
        email: "",
    });

    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleChange = ({currentTarget: input}) => {
        setData({...data, [input.name]: input.value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const url = process.env.REACT_APP_API + "/auth/forgotPassword";
            const {data: res} = await axios.post(url, data);
            console.log(res);
            toast.success(res.message);
            navigate("/login");
        } catch (error) {
            if (
                error.response &&
                error.response.status >= 400 &&
                error.response.status <= 500
            ) {
                toast.error(error.response.data.message);
                setError(error.response.data.message);
            }
        }
    };

    return (
        <>
            {
                !token ?
                    <div className="login">
                        <div className="login__left">
                            <img src="/assets/images/img-login.svg" alt="" width="382" height="603"/>
                        </div>
                        <div className="login__right">
                            <img src="/assets/images/logo-stepworks.svg" alt="" width="166" height="30"
                                 className="login__logo"/>
                            <h1 className="h2 login__title">Forgot password</h1>

                            <form action="" onSubmit={handleSubmit}>
                                <input type="email" name="email" id="email" value={data.email} className="login__input"
                                       placeholder="Email" onChange={handleChange} required/>
                                <button type="submit" className="btn login__btn">Reset my password</button>
                            </form>
                            {error && <div className="login__error">{error}</div>}
                        </div>
                    </div>
                    :
                    <Navigate to="/"/>
            }
        </>
    );
}

export default ForgotPassword;
  