import {Link} from "react-router-dom";

function Login() {
    return (
        <main className="container">
            <h1>404 Page not found.</h1>
            <Link to="/">Back</Link>
        </main>
    );
}

export default Login;
  