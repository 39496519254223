//React Imports
import {useCallback, useEffect, useRef, useState} from "react";
import {Link, useParams, useLocation, useOutletContext} from "react-router-dom";

//Redux Imports
import {connect} from "react-redux";
import {downloadFile, deleteFile} from '../../store/actions/index.js';

//Axios Import
import axiosAuth from "../../axios/";

//Components Import
import SearchFilter from "../../components/SearchFilter";
import FilterBar from "../../components/FilterBar";

//Functions Import
import ProjectFileList from "../../components/ProjectFileList";

function ProjectFiles(props) {
    const location = useLocation();
    const {projectId} = useParams();
    const {user, project} = useOutletContext();
    const [files, setFiles] = useState([]);
    const [tagList, setTagList] = useState(new Set());
    const [factor, setFactor] = useState(localStorage.getItem(`${user._id}_${projectId}_project_file_factor`) ?? 'created_at');
    const factors = [
        {label: 'Created date',     value: 'created_at'},
        {label: 'File name',        value: 'filename'},
        {label: 'Download time',    value: 'downloadTime'},
    ]
    const [filter, setFilter] = useState({
        descendingOrder: (localStorage.getItem(`${user._id}_${projectId}_project_file_order`) ?? 'desc') === 'desc',
        query: ''
    });
    const sortFiles = useRef([...files]);
    const [sortedMonthFiles, setSortedMonthFiles] = useState({});
    const update = useRef(false);

    function groupBy(items) {
        return items.reduce((acc, curr) => {
            if (curr.monthName) {
                const currentItems = acc[curr.monthName];

                return {
                    ...acc,
                    [curr.monthName]: currentItems ? [...currentItems, curr] : [curr]
                };
            }
            return acc;
        }, {});
    }

    const updateFilesSorting = useCallback(() => {
        localStorage.setItem(`${user._id}_${projectId}_project_file_order`, filter.descendingOrder ? 'desc' : 'asc');
        localStorage.setItem(`${user._id}_${projectId}_project_file_factor`, factor);

        if (sortFiles.current) {
            sortFiles.current.forEach((file) => {
                let strDate = new Date(file.created_at);
                let strConcat = '';
                strConcat += file.filename + ' ';
                strConcat += file.tags.map((tag) => {
                    return tag
                }).join(' ') + ' ';
                strConcat += file.remarks + ' ';
                strConcat += strDate.toLocaleString() + ' ' + strDate.toLocaleString('default', {month: 'long'});
                strConcat = strConcat.toLowerCase();

                file.monthName = strDate.toLocaleString('default', {month: 'long'}) + ' ' + strDate.getFullYear();

                if (document.querySelector(`.project-files__item[data-fileid="${file._id}"]`)) {
                    document.querySelector(`.project-files__item[data-fileid="${file._id}"]`).style.display =
                        strConcat.indexOf(filter.query.toLowerCase()) >= 0 ? 'flex' : 'none';
                }
            });

            sortFiles.current.map((file) => ({
                ...file,
                monthName: new Date(file.created_at).toLocaleString('default', {month: 'long'}) + ' ' + new Date(file.created_at).getFullYear(),
            }));

            sortFiles.current.sort((a, b) => {
                switch (factor) {
                    case 'filename':
                        return filter.descendingOrder ?
                            -1 * (a.filename.localeCompare(b.filename))
                            :
                            a.filename.localeCompare(b.filename);
                    case 'downloadTime':
                        return filter.descendingOrder ?
                            new Date(b.downloadTime) - new Date(a.downloadTime)
                            :
                            new Date(a.downloadTime) - new Date(b.downloadTime);
                    case 'created_at':
                    default:
                        return filter.descendingOrder ?
                            new Date(b.created_at) - new Date(a.created_at)
                            :
                            new Date(a.created_at) - new Date(b.created_at);
                }
            });

            setFiles(sortFiles.current);
        }

        update.current = false;

        setSortedMonthFiles(groupBy(sortFiles.current));
    }, [factor, filter.descendingOrder, filter.query, user._id, projectId]);

    useEffect(() => {
        const getFiles = async () => {
            try {
                const {data} = await axiosAuth.post('/file/fetch', {
                    fileIdList: project.files,
                    factor: localStorage.getItem(`${user._id}_${projectId}_project_file_factor`) ?? 'created_at',
                    order: (localStorage.getItem(`${user._id}_${projectId}_project_file_order`) ?? 'asc') === 'asc'
                });
                const fileData = data.filter((item) => {
                    return item.tab === 'project';
                });
                fileData.forEach((file) => {
                    file.tags.forEach((tag) => {
                        tagList.add(tag);
                    });
                    setTagList(tagList);
                });
                setFiles(fileData);
                sortFiles.current = fileData;
                update.current = true;
            } catch (error) {
                console.log(error)
            }
        };

        getFiles().then();
    }, [project, tagList, user._id, projectId]);

    useEffect(() => {
        updateFilesSorting();
    }, [updateFilesSorting, factor, filter.descendingOrder, filter.query]);

    useEffect(() => {
        sortFiles.current = [...files];
        if (update.current) updateFilesSorting();
    }, [files, updateFilesSorting]);

    const handleDownloadedFile = async (event, filePath, filename) => {
        try {
            const {data} = await props.downloadFile(user._id, event.currentTarget.dataset.fileid, projectId);
            const newFiles = [...files];

            newFiles.forEach((newFile) => {
                if (newFile._id === data.file._id) newFile.downloadTime = data.file.downloadTime;
            });

            setFiles(newFiles);

            axiosAuth({
                method: "get",
                url: filePath,
                transformResponse: [
                    function (data) {
                        return data;
                    }
                ],
                responseType: 'blob',
            }).then((response) => {
                download(
                    response.data,
                    response.headers["content-type"],
                    filename
                );
            });
        } catch (error) {
            console.log(error)
        }
    }

    const download = (content, mimeType, filename) => {
        const a = window.document.createElement("a"); // Create "a" element
        const blob = new Blob([content], { type: mimeType }); // Create a blob (file-like object)
        const url = URL.createObjectURL(blob); // Create an object URL from blob

        a.setAttribute("href", url); // Set "a" element link
        a.setAttribute("download", filename); // Set download filename
        a.click(); // Start downloading
    }

    const handleTagOnClick = (tag) => setFilter({...filter, 'query': tag});

    const deleteFile = async (event) => {
        const fileIdx = files.findIndex((file) => {
            return file._id === event.target.dataset.fileid;
        });
        const file = files[fileIdx];
        updateFilesSorting();
        await props.deleteFile(project, file);
    }

    return (
        <>
            <SearchFilter placeholder="Search by keyword, status or type"
                          handleChange={(e) => setFilter({...filter, 'query': e.target.value})}
                          handleClick={() => setFilter({...filter, 'query': ''})}
                          handleTagOnClick={handleTagOnClick}
                          value={filter.query}
                          tags={[...tagList]}
            />
            <FilterBar descendingOrder={filter.descendingOrder}
                       initFactor={factor}
                       factorList={factors}
                       updateSorting={() => setFilter({...filter, 'descendingOrder': !filter.descendingOrder})}
                       updateFactor={(e) => setFactor(e.target.value)}
            />
            {props.user.role === "admin" &&
                <Link className="h2 btn--action gap-2 my-2 mt-lg-0 mb-lg-4"
                      to={`/project/${projectId}/fileupload/project`}
                      state={{background: location, tab: 'project'}}
                >
                    <i className="material-symbols-outlined text--red icon icon--large">add_circle</i>
                    <span>Upload files</span>
                </Link>
            }
            {files.length > 0 ?
                <div className="project-files__list">
                    {factor === 'created_at' && sortedMonthFiles ?
                        Object.keys(sortedMonthFiles).map((key) =>
                            <div key={key} className="mb-4">
                                <div className="my-3">{key}</div>
                                <ProjectFileList files={sortedMonthFiles[key]}
                                                 handleTagOnClick={handleTagOnClick}
                                                 project={project}
                                                 handleDownloadedFile={handleDownloadedFile}
                                                 deleteFile={deleteFile}
                                                 location={location}
                                                 user={user}
                                />
                            </div>
                        )
                        :
                        <ProjectFileList files={files}
                                         handleTagOnClick={handleTagOnClick}
                                         project={project}
                                         handleDownloadedFile={handleDownloadedFile}
                                         deleteFile={deleteFile}
                                         location={location}
                                         user={user}
                        />
                    }
                </div>
                :
                <div className="project-files__empty">No files added yet</div>
            }
        </>
    );
}

const mapStateToProps = state => {
    return {
        user: state.user.user,
        project: state.global.project,
        file_deleted: state.global.file_deleted,
        file_uploaded: state.global.file_uploaded,
    };
};

export default connect(mapStateToProps, {
    deleteFile,
    downloadFile,
})(ProjectFiles);