export function toTitleCase(str) {
  return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()
}

export function confirmDialog(msg) {
  return new Promise(function (resolve, reject) {
    let confirmed = window.confirm(msg);
    return confirmed ? resolve(true) : reject(false);
  });
}

export function getFileType(fullName) {
  return fullName.split('.').pop();
}

export function getFileName(fullName) {
  let clone = fullName.split('.');
  clone.pop();
  return clone.join('.');
}

export function convertBytetoMB(size) {
  return parseFloat((size / 1024 / 1024).toPrecision(2));
}

export function buildRSDataFormat(option, capitalise) {
  let label = option.charAt(0).toUpperCase() + option.substr(1).toLowerCase();
  if (capitalise) {
    label = option.toUpperCase();
  }
  return {
    label,
    value: option,
  }
}