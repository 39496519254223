//Redux Types imports
import { GET_PROJECTS, GET_PROJECT_MEMBERS, UPDATE_PROJECT, DELETE_PROJECT, CREATE_SCOPE, UPDATE_SCOPE, DELETE_SCOPE, CREATE_TAG, MANAGE_PROJECT_MEMBERS, UPDATE_PROJECT_MEMBER } from "../actions/types/project";

const INITIAL_STATE = {
    project: {},
    projectList: [],
    projectMembers: [],
    updatedMembers: [],
    updatedMember: {},
    scope_created: '',
    tag_created: '',
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case GET_PROJECTS:
      case DELETE_PROJECT:
        return {
          ...state,
          projectList: action.payload
        };  
      case UPDATE_PROJECT:
        return {
          ...state,
          project: action.payload
        }
      case GET_PROJECT_MEMBERS:
        return {
          ...state,
          projectMembers: action.payload
        }; 
      case UPDATE_PROJECT_MEMBER:
        return {
          ...state,
          updatedMember: action.payload
        }; 
      case MANAGE_PROJECT_MEMBERS:
        return {
          ...state,
          updatedMembers: action.payload
        };        
      case CREATE_SCOPE:
        return {
          ...state,
          scope_created: action.payload
        }
      case UPDATE_SCOPE:
        return {
          ...state,
          scope_updated: action.payload
        }
      case DELETE_SCOPE:
        return {
          ...state,
          scope_deleted: action.payload
        }
      case CREATE_TAG:
        return {
          ...state,
          tag_created: action.payload
        }
      default:
        return state;
    }
  };
  