//React Imports
import React, {useEffect, useState} from 'react';
import {useNavigate, useLocation, useParams, useOutletContext} from "react-router-dom";

//Redux Imports
import {connect} from "react-redux";
import {createScope, updateScope} from '../../store/actions';

//Component Imports 
import DatePicker from "react-datepicker";
import Form from 'react-bootstrap/Form';
import {toast} from 'react-toast';

function ScopeManager(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const {project} = useOutletContext();
    const {scopeId, projectId} = useParams();
    const {scopeItem} = location.state || {};
    const editMode = props.isEdit && scopeId;
    const scope = scopeItem !== undefined ?
        scopeItem
        :
        (editMode ? project.scope.filter(obj => {
            return obj._id === scopeId
        })[0] : {});
    const [data, setData] = useState({
        task:         editMode ? scope.task : '',
        targetDate:   editMode ? new Date(scope.targetDate) : new Date(),
        status:       editMode ? scope.status : '',
        progress:     editMode ? scope.progress : 0,
        customStatus: editMode ? scope.customStatus : false,
    });

    const computedProgress = (p) => {
        const progress = Number(p);
        if (progress <= 0) return 'Not started';
        else if (progress > 0 && progress < 100) return 'In progress';
        else if (progress >= 100) return 'Completed';
    }

    useEffect(() => {
        if (!data.customStatus) setData(d => ({...d, status: computedProgress(data.progress)}));
    }, [data.progress, data.customStatus]);

    const handleChange = ({currentTarget: input}) => {
        const newValue = input.name === 'customStatus' ? input.checked : input.value;
        setData({...data, [input.name]: newValue});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!props.isEdit) {
            // Create scope
            await props.createScope(projectId, Object.assign({...data}, {progress: parseInt(data.progress)}))
                .then((response) => {
                    toast.success(response.message);
                    navigate(props.background ? -1 : `/project/${project._id}/dashboard`);
                })
                .catch((error) => {
                    if (error.response && error.response.status >= 400 && error.response.status <= 500) {
                        toast.error(error.message);
                    }
                });
        } else {
            // Edit scope
            await props.updateScope(projectId, scope._id, Object.assign({...data}, {progress: parseInt(data.progress)}))
                .then((response) => {
                    toast.success(response.message);
                    navigate(props.background ? -1 : `/project/${project._id}/dashboard`);
                })
                .catch((error) => {
                    if (error.response && error.response.status >= 400 && error.response.status <= 500) {
                        toast.error(error.message);
                    }
                });
        }
    }

    return (
        <>
            <div className={props.background && 'form__container'}>
                {
                    props.background ?
                        <h1>Scope Manager</h1>
                        :
                        <h2 className="mt-4 pt-2 fw-bold">Scope Manager</h2>
                }
                <Form action="" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-lg-6">
                            <Form.Label htmlFor="task">Task *</Form.Label>
                            <Form.Control id="task"
                                          name="task"
                                          value={data.task}
                                          type="text"
                                          onChange={handleChange}
                                          required
                            />
                        </div>
                        <div className="col-lg-6">
                            <Form.Label htmlFor="targetDate">Target date *</Form.Label>
                            <div>
                                <DatePicker id="targetDate"
                                            name="targetDate"
                                            selected={data.targetDate}
                                            dateFormat="dd MMM yyyy"
                                            wrapperClassName="d-block"
                                            onChange={(date) => setData({...data, targetDate: date})}
                                            required
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 mb-4">
                            <Form.Label htmlFor="progress">Progress</Form.Label>
                            <div className="d-flex align-items-end gap-4">
                                <div className="w-100">
                                    <Form.Range id="progress"
                                                name="progress"
                                                value={data.progress}
                                                onChange={handleChange}
                                                min="0"
                                                max="100"
                                    />
                                </div>
                                <div>{data.progress}%</div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="d-flex justify-content-between">
                                <Form.Label htmlFor="status">Status</Form.Label>
                                <Form.Check
                                    className="small"
                                    type="checkbox"
                                    label="Customize status name"
                                    name="customStatus"
                                    id="customStatus"
                                    checked={data.customStatus}
                                    onChange={handleChange}
                                />
                            </div>
                            <Form.Control id="status"
                                          name="status"
                                          value={`${data.status}`}
                                          type="text"
                                          onChange={handleChange}
                                          disabled={!data.customStatus}
                                          required={data.customStatus}
                            />
                        </div>
                    </div>
                    <button type="submit" className="btn">Submit</button>
                </Form>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    return {};
};

export default connect(mapStateToProps, {
    createScope,
    updateScope,
})(ScopeManager);