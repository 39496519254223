//React imports
import {useState, useEffect, useCallback} from 'react';

//Redux imports
import {connect} from "react-redux";
import {updateUser} from "../store/actions";

//components import
import {GithubPicker} from 'react-color';
import {toast} from "react-toast";

function ProfileImage(props) {
    const {user, targetUser, currentUser, updateUser} = props;
    const initials = targetUser.firstName.substring(0, 1) + targetUser.lastName.substring(0, 1);
    const colorArr = ['#B80000', '#DB3E00', '#FCCB00', '#008B02', '#006B76', '#1273DE', '#004DCF', '#5300EB', '#727272'];
    const [color, setColor] = useState(targetUser.color);
    const [showElement, setShowElement] = useState(false);

    useEffect(() => {
        setColor(targetUser.color);
    }, [targetUser]);

    //(show / hide) color picker
    const toggleColorPicker = () => {
        setShowElement(!showElement);
    };

    // update color
    const updateColor = useCallback(async () => {
        setShowElement(se => !se);
        try {
            let userData = {...targetUser};
            userData.color = color;
            const response = await updateUser(userData, currentUser);
            if (response) toast.success(response.data.message)
        } catch (error) {
            if (
                error.response &&
                error.response.status >= 400 &&
                error.response.status <= 500
            ) toast.error(error.response.data.message);
        }
    }, [color, targetUser, updateUser, currentUser]);

    return (
        <>
            <div className="profile-image">
                <span className="initials d-flex align-items-center justify-content-center"
                      style={{backgroundColor: color}}
                      onClick={user.role === 'admin' || currentUser ? toggleColorPicker : null}
                >
                    {initials}
                    {(user.role === 'admin' || currentUser) && <i className="material-symbols-outlined icon edit">edit</i>}
                </span>
                {showElement && (
                    <div className='color-picker-area bg-white'>
                        <GithubPicker colors={colorArr} onChangeComplete={(color) => setColor(color.hex)}/>
                        <div className="d-flex gap-2">
                            <button className='btn btn--small' onClick={updateColor}>Update</button>
                            <button className='btn btn--small btn--outline' onClick={toggleColorPicker}>Close</button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

const mapStateToProps = state => {
    return {};
};

export default connect(mapStateToProps, {updateUser})(ProfileImage);